import { useState } from "react";
import useAddFSDoc from "../../hooks/db/useAddFSDoc";
import { Box, Button, Typography, LinearProgress, Container, Grid } from "@mui/material";
import Papa, { ParseResult } from "papaparse";
import useGetFSDocs from "../../hooks/db/useGetFSDocs";
import { ProgramRecord, ProviderRecord } from "../../types/types";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { LogEventType } from "../../../shared/types/logEnums";

type TransformedProgram = {
  // Provider fields
  providerName: string;
  homeURL: string;
  providerDescription: string;
  city: string;
  state: string;
  zip: string;
  address: string;
  admissionPercent: number | null;
  completionRate: number | null;
  ownership: string;

  // Program fields
  programName: string;
  programDescription: string;
  credentialLevel: string;
  durationMonths: number | null;
  durationYears: number | null;
  cost: number | null;
  modality: string;
  applyURL: string;
  cipCode: string;
  startingSalary: number | null;
  onlineOnly: boolean | null;
  willowSubIndustryId: string;
  commitment: string;
  programType: boolean | null;
};

const UploadNonCollegeDataContainer = () => {
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [isUpLoading, setIsUpLoading] = useState<boolean>(false);
  const { addFSDoc } = useAddFSDoc();
  const { getFSDocs } = useGetFSDocs();
  const { submitLog } = useLogger();

  // National DB Toggle
  const national = true;
  const providerCollection = "providers";
  const programsCollection = "allPrograms";
  const programQueryCollection = "programsForQuery";

  // Helper function to parse booleans
  function parseBoolean(value: any) {
    if (typeof value === "boolean") return value;
    if (typeof value === "string") {
      const val = value.trim().toLowerCase();
      if (val === "1" || val === "true") return true;
      if (val === "0" || val === "false") return false;
    }
    if (typeof value === "number") {
      const val = value;
      if (val === 1) return true;
      if (val === 0) return false;
    }
    return null;
  }

  // Helper functions for type conversion
  const parseNumber = (value: any) => {
    if (value === null || value === undefined || value === "") return null;
    const num = Number(value);
    return isNaN(num) ? null : num;
  };

  const parsePercentage = (value: any) => {
    if (typeof value === "string" && value.endsWith("%")) {
      const num = parseNumber(value.slice(0, -1));
      return num !== null ? num : null;
    }
    return parseNumber(value);
  };

  const trimString = (value: any) => {
    if (typeof value === "string") {
      const trimmed = value.trim();
      return trimmed === "" ? null : trimmed;
    }
    return value;
  };

  // Transform raw program data into standardized format
  const transformProgramData = (rawProgram: any): TransformedProgram => {
    const transformedData = {
      // Provider fields
      providerName: trimString(rawProgram.providerName),
      homeURL: trimString(rawProgram.homeURL),
      providerDescription: trimString(rawProgram.providerDescription),
      city: trimString(rawProgram.city),
      state: trimString(rawProgram.state),
      zip: trimString(rawProgram.zip),
      address: trimString(rawProgram.address),
      admissionPercent: parsePercentage(rawProgram.admissionPercent),
      completionRate: parsePercentage(rawProgram.completionRate),
      ownership: trimString(rawProgram.ownership),

      // Program fields
      programName: trimString(rawProgram.programName),
      programDescription: trimString(rawProgram.programShortDescription),
      credentialLevel: trimString(rawProgram.credentialLevel),
      durationMonths: parseNumber(rawProgram.durationMonths),
      durationYears: parseNumber(rawProgram.durationYears),
      cost: parseNumber(rawProgram.totalProgramCost),
      modality: trimString(rawProgram.modality),
      applyURL: trimString(rawProgram.applyURL),
      cipCode: trimString(rawProgram.cipCode),
      startingSalary: parseNumber(rawProgram.program1yrEarnings),
      onlineOnly: parseBoolean(rawProgram.onlineOnly),
      willowSubIndustryId: trimString(rawProgram.willowSubIndustryId) || null, // Temp workaround, data field doesn't exist
      commitment: trimString(rawProgram.commitment),
      programType: parseBoolean(rawProgram.programType),
    };

    return transformedData;
  };

  const parseUpload = async (programs: any) => {
    // Initialize counters
    let providerCount = 0;
    let programCount = 0;
    let programQueryCount = 0;

    // Group programs by provider
    const programsByProvider = programs.reduce((acc: { [key: string]: any[] }, program: any) => {
      const transformed = transformProgramData(program);
      if (!acc[transformed.providerName]) {
        acc[transformed.providerName] = [];
      }
      acc[transformed.providerName].push(transformed);
      return acc;
    }, {});

    // Process each provider and its programs
    for (const [providerName, providerPrograms] of Object.entries<TransformedProgram[]>(
      programsByProvider
    )) {
      // Try to find existing provider
      let providerId = await getProviderId(providerName);

      // Create new provider if it doesn't exist
      if (!providerId) {
        const avgCompletionRate =
          Math.round(
            (providerPrograms.reduce((acc, curr) => {
              return curr.completionRate ? acc + curr.completionRate : acc;
            }, 0) /
              (providerPrograms.filter((p) => p.completionRate !== null).length || 1)) *
              100
          ) / 100;

        const firstProgram = providerPrograms[0]; // use provider from first program on all

        const providerData = {
          createdAt: new Date().toISOString(),
          lastUpdatedAt: new Date().toISOString(),
          providerName,
          homeURL: firstProgram.homeURL,
          providerDescription: firstProgram.providerDescription,
          city: firstProgram.city,
          state: firstProgram.state,
          zip: firstProgram.zip,
          address: firstProgram.address,
          admissionPercent: firstProgram.admissionPercent,
          completionRate: avgCompletionRate,
          ownership: firstProgram.ownership,
          ipeds: false,
          openAdmin: firstProgram.admissionPercent === 100,
        };

        const providerResponse = await addFSDoc<ProviderRecord>({
          col: providerCollection,
          data: providerData,
          national,
        });

        if (providerResponse.id) {
          providerId = providerResponse.id;
          providerCount++;
        } else {
          console.error(`Failed to create provider: ${providerName}`);
          continue;
        }
      }

      // Process all programs for this provider
      for (const program of providerPrograms) {
        const programName = trimString(program.programName);

        // Skip if program already exists
        const programExists = await getExistingProgram(programName, false);
        if (programExists) {
          continue;
        }

        const programROI = calculateROI(program.startingSalary, program.cost);

        const programData = {
          providerId,
          programName: programName,
          programDescription: program.programDescription,
          completionRate: program.completionRate,
          credLevel: 1,
          credentialLevel: program.credentialLevel,
          durationMonths: program.durationMonths,
          durationYears: program.durationYears,
          cost: program.cost,
          modality: program.modality,
          applyURL: program.applyURL,
          cipCode: program.cipCode,
          startingSalary: program.startingSalary,
          willowROI: programROI,
          programROI: programROI,
          createdAt: new Date().toISOString(),
          lastUpdatedAt: new Date().toISOString(),
          onlineOnly: program.onlineOnly,
          willowSubIndustryId: program.willowSubIndustryId,
          commitment: program.commitment,
          ipeds: false,
        };

        // Create program record
        const programResponse = await addFSDoc<ProgramRecord>({
          col: programsCollection,
          data: programData,
          national,
        });

        if (!programResponse) {
          continue;
        }

        programCount++;

        // Create program query record if it doesn't exist
        const programQueryExists = await getExistingProgram(programName, true);

        if (!programQueryExists) {
          const programQueryData = {
            id: programResponse.id,
            providerId,
            programName: programName,
            providerName: providerName,
            cipCode: program.cipCode,
            credLevel: 1,
            admissionPercent: program.admissionPercent,
            cost: program.cost,
            city: program.city,
            state: program.state,
            completionRate: program.completionRate,
            willowROI: programROI,
            programROI: programROI,
            ipeds: false,
            programType: program.programType,
            onlineOnly: program.onlineOnly,
            startingSalary: program.startingSalary,
            durationMonths: program.durationMonths,
            durationYears: program.durationYears,
          };

          await addFSDoc({
            col: programQueryCollection,
            data: programQueryData,
            national,
          });
          programQueryCount++;
        }
      }
    }

    return {
      count: programs.length,
      providerCount,
      programCount,
      programQueryCount,
    };
  };

  const handleUpload = async () => {
    setIsUpLoading(true);

    try {
      if (!csvFile) {
        alert("Please provide a csv file.");
        return;
      }

      // Convert Papa.parse to Promise
      const results: ParseResult<any> = await new Promise((resolve, reject) => {
        Papa.parse(csvFile, {
          header: true,
          skipEmptyLines: true,
          complete: resolve,
          error: reject,
        });
      });

      // Filter out empty provider names
      const programs = results.data.filter((doc: any) => doc.providerName !== "");

      // Group by provider to show unique providers
      const uniqueProviders = new Set(programs.map((p: any) => p.providerName));

      // Uncomment and use your parseUpload function
      const response = await parseUpload(programs);

      if (!response) {
        alert("Error uploading programs.");
      } else {
        alert(
          `CSV ${csvFile.name} imported!\n` +
            `Total Program Count: ${programs.length} entries\n` +
            `Total Iterations: ${response.count}\n` +
            `Unique Provider Count: ${uniqueProviders.size}\n` +
            `Providers Added: ${response.providerCount}\n` +
            `Programs Added: ${response.programCount}\n` +
            `Program Query Added: ${response.programQueryCount}`
        );
      }
    } catch (error) {
      submitLog({
        error: error instanceof Error ? error : new Error("Error importing csv"),
        snackbarMessage: "There was an error importing the csv. Please try again.",
        eventType: LogEventType.ADMIN_UPLOAD_CSV_DATA,
        file: "UploadNonCollegeDataContainer.tsx",
      });
      alert("There was an error importing the csv. Please try again.");
    } finally {
      setCsvFile(null);
      setIsUpLoading(false);

      // Reset the file input
      const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
      if (fileInput) fileInput.value = "";
    }
  };

  const getProviderId = async (provider_name: string) => {

    const provider: ProviderRecord[] = await getFSDocs({
      col: "testProviders",
      config: { where: ["providerName", "==", provider_name] },
      national,
    });

    if (provider === undefined || provider.length < 1) return;


    return provider[0].id;
  };

  const getExistingProgram = async (program_name: string, query: boolean) => {

    const program: ProgramRecord[] = await getFSDocs({
      col: query ? "testProgramsForQuery" : "testPrograms",
      config: { where: ["programName", "==", program_name] },
      national,
    });
    if (program === undefined || program.length < 1) return;

    return true;
  };

  // Helper function to calculate ROI
  const calculateROI = (startingSalary: any, cost: any) => {
    const salary = parseNumber(startingSalary);
    const programCost = parseNumber(cost);
    if (!salary || !programCost) return null;
    const ROI = (salary - 30000) * 10 - programCost;
    return ROI;
  };

  return (
    <Container maxWidth={false}>
      <Grid container>
        <Box mt={4} p={3} boxShadow={3} borderRadius={2} bgcolor="background.paper">
          <Typography variant="h5" gutterBottom>
            Upload CSV & save data to Firestore
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Please upload CSV files and avoid using multiple tabs to upload them simultaneously.
          </Typography>

          <Box mt={3}>
            <Button
              variant="contained"
              component="label"
              disabled={isUpLoading}
              fullWidth
              sx={{ mb: 2 }}
            >
              {csvFile ? csvFile.name : "Select CSV File"}
              <input
                type="file"
                hidden
                accept="text/csv"
                onChange={(e) => setCsvFile(e.target.files ? e.target.files[0] : null)}
              />
            </Button>

            {isUpLoading && <LinearProgress />}

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleUpload}
              disabled={isUpLoading}
            >
              {isUpLoading ? "Uploading..." : "Upload File"}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Container>
  );
};

export default UploadNonCollegeDataContainer;
