import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { Dispatch } from "react";
import { states } from "../../../shared/assets/data/states";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import cities from "../../../shared/assets/data/cities.json";
import { useField, useFormikContext } from "formik";
import { AddressComponents } from "../../../shared/types/types";

type FormType = {
  gpaValue: number | string;
  gpaMax: number | string;
  act: string;
  sat: string;
  frlStatus: string;
  address: AddressComponents;
  statesForRecommendations: string[];
  citiesForRecommendations: string[];
  includeOnlineOnly: string;
  programTypeForRecommendations: string;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  name: string;
  setListOfCities: Dispatch<React.SetStateAction<string[]>>;
};

const StateSelect = ({ name, setListOfCities }: Props) => {
  const [field, meta, helpers] = useField(name);
  const { setFieldValue, values } = useFormikContext<FormType>();

  const handleStatesChange = (_: any, value: string[]) => {
    if (value.length <= 3) {
      helpers.setValue(value);
      setFieldValue(name, value);

      // Update list of cities based on selected states
      const citiesArray: string[] = [];
      value.forEach((state) => {
        citiesArray.push(...cities[state as keyof typeof cities]);
      });
      //sort citiesArray
      citiesArray.sort();
      setListOfCities(citiesArray);

      // Remove cities that are no longer in the selected states
      const selectedCities = values.citiesForRecommendations || [];
      const updatedSelectedCities = selectedCities.filter((city: string) =>
        citiesArray.includes(city)
      );
      setFieldValue("citiesForRecommendations", updatedSelectedCities);
    }
  };

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel
        shrink
        htmlFor="states-filter"
        variant="standard"
        sx={{ display: "flex", fontSize: 16, fontWeight: 600, top: "-20px" }}
      >
        What states do you want to see programs from (select up to 3)?
      </InputLabel>
      <Autocomplete
        multiple
        id="states-filter"
        options={states}
        value={field.value}
        onChange={handleStatesChange}
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => {
          const { ...optionProps } = props;
          return (
            <li {...optionProps} key={option}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            autoComplete="new-password"
          />
        )}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "rgba(0, 0, 0, 0.23)",
            },
            "&:hover fieldset": {
              borderColor: "rgba(0, 0, 0, 0.23)",
            },
            "&.Mui-focused fieldset": {
              borderColor: "primary.main",
            },
          },
        }}
      />
      <FormHelperText>Leave Blank for All States</FormHelperText>
    </FormControl>
  );
};

export default StateSelect;
