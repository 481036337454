import { ProviderProgramRecord } from "../types/types";
import { formatCurrency } from "./formatUtils";

type CalcROIEarnings = {
  earnings: number | null;
  cost: number | null;
  durationYears: number | null;
  durationMonths: number | null;
  totalCost: number | null;
};

const BASELINE_EARNINGS = 30000;

export const calcROI = ({
  earnings,
  cost,
  durationYears,
  durationMonths,
  totalCost,
}: CalcROIEarnings) => {
  if (!earnings || (cost === null && totalCost === null)) return null;
  if (!durationYears && !durationMonths) return null;
  if (!durationYears) durationYears = 0;
  if (!durationMonths) durationMonths = 0;
  const totalDuration = durationYears + durationMonths / 12;
  const totalCostWithDuration = totalCost !== null ? totalCost : cost ? cost : 0 * totalDuration;
  return (earnings - BASELINE_EARNINGS) * 10 - totalCostWithDuration;
};

type DetermineROIProps = {
  program: ProviderProgramRecord;
  personalizedCost: number | null;
};

export const determineROI = ({
  program,
  personalizedCost,
}: DetermineROIProps) => {
  const roi = program.programROI
    ? program.programROI
    : program.providerROI
    ? program.providerROI
    : null;
  if (personalizedCost === null) {
    return roi ? formatCurrency({ amount: roi }) : "Unknown";
  } else {
    const personalizedROI = calcROI({
      earnings: program.fiveYearEarnings
        ? program.fiveYearEarnings
        : program.program1yrEarnings
        ? program.program1yrEarnings
        : program.provider4yrEarnings
        ? program.provider4yrEarnings
        : program.provider1yrEarnings,
      cost: program.academicCostsPerYear,
      durationYears: program.durationYears,
      durationMonths: program.durationMonths,
      totalCost: personalizedCost,
    });
    return personalizedROI ? formatCurrency({ amount: personalizedROI }) : "Unknown";
  }
};

type GenerateAccessLevelProps = {
  gpaValue: number | null;
  gpaMax: number | null;
  act25th: number | null;
  act75th: number | null;
  openAdmin: boolean;
  admissionPercent: number | null;
  act: string | null;
};

export const generateAccessLevel = ({
  gpaValue,
  gpaMax,
  act25th,
  act75th,
  openAdmin,
  admissionPercent,
  act,
}: GenerateAccessLevelProps) => {
  // Ensure GPA percentage is within the valid range (0 to 100)
  if (openAdmin) return "Safety";
  const actNumber = act ? parseInt(act) : null;
  const gpaPercent = gpaValue && gpaMax ? gpaValue / gpaMax : null;
  if ((!gpaPercent || gpaPercent < 0 || gpaPercent > 1) && !act) {
    return "Unknown";
  }
  if ((!act25th || !act75th) && !admissionPercent) {
    return "Unknown";
  }

  type ConversionTableRow = {
    gpaPercent: number;
    act: number;
    safetyCutoff: number;
    reachCutoff: number;
  };

  // Define the conversion table
  const conversionTable: ConversionTableRow[] = [
    { gpaPercent: 36 / 36, act: 36, safetyCutoff: 0.5, reachCutoff: 0.1 },
    { gpaPercent: 35 / 36, act: 35, safetyCutoff: 0.5, reachCutoff: 0.15 },
    { gpaPercent: 34 / 36, act: 34, safetyCutoff: 0.55, reachCutoff: 0.15 },
    { gpaPercent: 33 / 36, act: 33, safetyCutoff: 0.55, reachCutoff: 0.2 },
    { gpaPercent: 32 / 36, act: 32, safetyCutoff: 0.55, reachCutoff: 0.2 },
    { gpaPercent: 31 / 36, act: 31, safetyCutoff: 0.55, reachCutoff: 0.25 },
    { gpaPercent: 30 / 36, act: 30, safetyCutoff: 0.6, reachCutoff: 0.25 },
    { gpaPercent: 29 / 36, act: 29, safetyCutoff: 0.6, reachCutoff: 0.3 },
    { gpaPercent: 28 / 36, act: 28, safetyCutoff: 0.6, reachCutoff: 0.3 },
    { gpaPercent: 27 / 36, act: 27, safetyCutoff: 0.6, reachCutoff: 0.35 },
    { gpaPercent: 26 / 36, act: 26, safetyCutoff: 0.65, reachCutoff: 0.35 },
    { gpaPercent: 25 / 36, act: 25, safetyCutoff: 0.65, reachCutoff: 0.4 },
    { gpaPercent: 24 / 36, act: 24, safetyCutoff: 0.65, reachCutoff: 0.45 },
    { gpaPercent: 23 / 36, act: 23, safetyCutoff: 0.65, reachCutoff: 0.5 },
    { gpaPercent: 22 / 36, act: 22, safetyCutoff: 0.7, reachCutoff: 0.55 },
    { gpaPercent: 21 / 36, act: 21, safetyCutoff: 0.7, reachCutoff: 0.6 },
    { gpaPercent: 20 / 36, act: 20, safetyCutoff: 0.75, reachCutoff: 0.7 },
    { gpaPercent: 19 / 36, act: 19, safetyCutoff: 0.75, reachCutoff: 0.7 },
    { gpaPercent: 18 / 36, act: 18, safetyCutoff: 0.8, reachCutoff: 0.7 },
    { gpaPercent: 17 / 36, act: 17, safetyCutoff: 0.8, reachCutoff: 0.75 },
    { gpaPercent: 16 / 36, act: 16, safetyCutoff: 0.85, reachCutoff: 0.8 },
    { gpaPercent: 15 / 36, act: 15, safetyCutoff: 0.85, reachCutoff: 0.85 },
    { gpaPercent: 14 / 36, act: 14, safetyCutoff: 0.9, reachCutoff: 0.9 },
    { gpaPercent: 13 / 36, act: 13, safetyCutoff: 0.9, reachCutoff: 0.9 },
    { gpaPercent: 12 / 36, act: 12, safetyCutoff: 0.95, reachCutoff: 0.9 },
    { gpaPercent: 11 / 36, act: 11, safetyCutoff: 0.95, reachCutoff: 0.9 },
  ];

  // Find the closest GPA percentage in the conversion table
  let closestGpaPercent: ConversionTableRow | null = null;
  if (gpaPercent && gpaPercent > 0 && gpaPercent < 1) {
    closestGpaPercent = conversionTable.reduce((prev, curr) =>
      Math.abs(curr.gpaPercent - gpaPercent) < Math.abs(prev.gpaPercent - gpaPercent)
        ? curr
        : prev
    );
  }

  if (actNumber && act25th && act75th) {
    if (actNumber < act25th) {
      return "Reach";
    } else if (actNumber >= act25th && actNumber <= act75th) {
      return "Target";
    } else if (actNumber > act75th) {
      return "Safety";
    } else {
      return "Unknown";
    }
  }
  if (closestGpaPercent && act25th && act75th) {
    if (closestGpaPercent.act < act25th) {
      return "Reach";
    } else if (closestGpaPercent.act >= act25th && closestGpaPercent.act <= act75th) {
      return "Target";
    } else if (closestGpaPercent.act > act75th) {
      return "Safety";
    } else {
      return "Unknown";
    }
  }
  if ((!act25th || !act75th) && admissionPercent && closestGpaPercent) {
    if (admissionPercent > closestGpaPercent.safetyCutoff) {
      return "Safety";
    } else if (
      admissionPercent <= closestGpaPercent.safetyCutoff &&
      admissionPercent >= closestGpaPercent.reachCutoff
    ) {
      return "Target";
    } else if (admissionPercent < closestGpaPercent.reachCutoff) {
      return "Reach";
    } else {
      return "Unknown";
    }
  }

  return "Unknown";
};