import { useState } from "react";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { SearchedProvider } from "../../types/types";
import { Box, Grid, Button, Typography, LinearProgress, Container } from "@mui/material";
import { SearchFilter } from "../../../students/components/explorePrograms/filters/SearchFilter";
import { Color } from "../../types/enums";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { LogEventType } from "../../../shared/types/logEnums";

const ProviderImageUploader = () => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [providers, setProviders] = useState<SearchedProvider[]>([]);
  const storage = getStorage();
  const { submitLog } = useLogger();

  const handleUpload = async () => {
    if (!imageFile) return;

    setIsLoading(true);

    try {
      const providerId = providers[0].providerId;
      const filePath = `public/college-images/${providerId}.jpg`;
      const storageRef = ref(storage, filePath);

      const result: any = await uploadBytes(storageRef, imageFile);

      const encodedFilePath = encodeURIComponent(filePath);
      const bucketName = result.bucket;
      const publicUrl = `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/${encodedFilePath}?alt=media`;
    } catch (error) {
      console.error("Error uploading image file:", error);
      submitLog({
        error: error instanceof Error ? error : new Error("Error importing image"),
        eventType: LogEventType.UPLOAD_PROVIDER_IMAGE_ERROR,
        file: "ProviderImageUploader.tsx",
      });
      alert(
        `There was an error uploading image for ${providers[0].providerName}. Please try again.`
      );
    } finally {
      setIsLoading(false);
      setImageFile(null);
      setProviders([]);
      // Reset the file input
      const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
      if (fileInput) fileInput.value = "";
    }
  };

  return (
    <Container maxWidth={false}>
      <Grid container>
        <Box mt={4} p={3} boxShadow={3} borderRadius={2} bgcolor="background.paper">
          <Typography variant="h5" gutterBottom>
            Upload Provider Images & save to public storage
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Please upload images files and avoid using multiple tabs to upload them simultaneously.
          </Typography>

          <Box mt={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                borderRadius: 2,
                background: Color.WHITE,
                px: 2,
                py: 1.5,
              }}
            >
              <SearchFilter
                searchedProviders={providers}
                setSearchedProviders={setProviders}
                multiple={false}
              />
            </Box>

            <Button
              variant="contained"
              component="label"
              disabled={!providers || providers.length < 1}
              fullWidth
              sx={{ mb: 2 }}
            >
              {imageFile ? imageFile.name : "Select an Image File"}
              <input
                type="file"
                hidden
                accept="image/png,jpg"
                onChange={(e) => {
                  setImageFile(e.target.files ? e.target.files[0] : null);
                }}
              />
            </Button>

            {isLoading && <LinearProgress />}

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleUpload}
              disabled={imageFile === null}
            >
              {isLoading ? "Uploading..." : "Upload File"}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Container>
  );
};

export default ProviderImageUploader;
