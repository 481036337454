import { Dispatch, SetStateAction, useState } from "react";
import { Box, Dialog, DialogContent, IconButton, Grid } from "@mui/material";
import useProviderDetailsDialog from "../../hooks/providerDetails/useProviderDetailsDialog";
import FeatherIcon from "../../assets/icons/featherIcons/FeatherIcon";
import { Color } from "../../types/enums";
import ProgramDetailsDialog from "../programDetails/ProgramDetailsDialog";
import ProviderInfo from "./ProviderInfo";
import ProviderStats from "./ProviderStats";
import { ProviderProgramRecord } from "../../types/types";
import ProgramList from "./ProgramList";

type Props = {
  isEditable?: boolean;
  providerId: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const ProviderDetailsDialog = ({ isEditable = true, providerId, open, setOpen }: Props) => {
  const [selectedLearnMoreProgram, setSelectedLearnMoreProgram] =
    useState<ProviderProgramRecord | null>(null);

    const { currentProvider, programsByProvider } = useProviderDetailsDialog({
      providerId,
    });

  if (!currentProvider) return null;

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="lg" onClose={() => setOpen(false)}>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: Color.GRAY_800,
            backgroundColor: Color.WHITE,
          }}
        >
          <FeatherIcon icon="close" strokeWidth="1" />
        </IconButton>
        <DialogContent>
          <Box>
            <ProviderInfo provider={currentProvider} />
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item sm={7}>
                  <ProviderStats provider={currentProvider} />
                  <ProgramList
                    programs={programsByProvider}
                    setSelectedLearnMoreProgram={setSelectedLearnMoreProgram}
                  />
                </Grid>
                {/* Video Section */}
                {/* <Grid item sm={5}></Grid> */}
              </Grid>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      {selectedLearnMoreProgram && (
        <ProgramDetailsDialog
          isEditable={isEditable}
          selectedProgram={selectedLearnMoreProgram}
          setSelectedProgram={setSelectedLearnMoreProgram}
        />
      )}
    </>
  );
};

export default ProviderDetailsDialog;
