import { atom } from "recoil";

export const staffSideBarRouterAtom = atom<boolean>({
  key: "staffSideBarRouterAtom",
  default: true,
});

export const staffTopBarRouterAtom = atom<boolean>({
    key: "staffTopBarRouterAtom",
    default: true,
});
