import { Box, Container, Paper, Typography } from "@mui/material";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import QuestionSelector from "./QuestionSelector";
import PreQuizQuestions from "./PreQuizQuestions";
import { useQuizDataContext } from "../../contexts/quizContext";
import LinearProgressWithLabel from "./LinearProgressWithLabel";
import { currentQuestionIndexAtom, questionsAtom } from "../../recoil/quizAtoms";

const TakeQuizContainer = () => {
  const { width } = useWindowDimensions();
  const questions = useRecoilValue(questionsAtom);
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const { loading } = useQuizDataContext();
  const currentQuestionIndex = useRecoilValue(currentQuestionIndexAtom);

  if (!loggedInStudent || loading) return null;

  const Content = loggedInStudent.preQuizComplete ? (
    <QuestionSelector loggedInStudent={loggedInStudent} />
  ) : (
    <PreQuizQuestions loggedInStudent={loggedInStudent} />
  );

  return (
    <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Box sx={{ py: 2 }}>
          {width > 900 ? (
            <Container maxWidth="sm" sx={{ mt: 2 }}>
              <Typography variant="h6">
                {loggedInStudent.preQuizComplete ? "Personality Type Quiz" : "Pre Quiz Survey"}
              </Typography>
              <Typography variant="body2" sx={{ mt: 0 }}>
                The questions below will help us match you with the best possible careers and
                college programs.
              </Typography>
              {loggedInStudent.preQuizComplete && (
                <Box sx={{ my: 2 }}>
                  <LinearProgressWithLabel
                    current={currentQuestionIndex + 1}
                    possible={questions.length}
                  />
                </Box>
              )}
              <Paper
                sx={{
                  p: 2,
                  mt: 1,
                  minHeight: "50VH",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {Content}
              </Paper>
            </Container>
          ) : (
            Content
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TakeQuizContainer;
