import { Box, LinearProgress, Typography } from "@mui/material";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { Color } from "../../types/enums";
import { ProviderRecord } from "../../types/types";
import { numberToPercent, numberToRound } from "../../utils/formatUtils";

type Props = {
  provider: ProviderRecord;
};

const COLORS = ["#137C67", "#1DBC9D", "#4BE2C4", "#B9F4E8", "#B85300", "#FF7E14", "#FFB375", "#FFEBDB"];

const ProviderStats = ({ provider }: Props) => {
  const blackPercent = provider.percentBlack ? Math.round(provider.percentBlack * 100) : 0;
  const latinxPercent = provider.percentHispanic ? Math.round(provider.percentHispanic * 100) : 0;
  const asianPercent = provider.percentAsian ? Math.round(provider.percentAsian * 100) : 0;
  const aianPercent = provider.percentAIAN ? Math.round(provider.percentAIAN * 100) : 0;
  const nhpiPercent = provider.percentNHPI ? Math.round(provider.percentNHPI * 100) : 0;
  const twoOrMorePercent = provider.percentTwoOrMore ? Math.round(provider.percentTwoOrMore * 100) : 0;
  const whitePercent = provider.percentWhite ? Math.round(provider.percentWhite * 100) : 0;
  const unknownPercent = 100 - (blackPercent + latinxPercent + asianPercent + aianPercent + nhpiPercent + twoOrMorePercent + whitePercent);

  const raceAndEthnicityDiversityData = [
    { name: "Black", value: blackPercent },
    { name: "Latinx", value: latinxPercent },
    { name: "Asian", value: asianPercent },
    { name: "AIAN", value: aianPercent },
    { name: "NHPI", value: nhpiPercent },
    { name: "Two or More", value: twoOrMorePercent },
    { name: "White", value: whitePercent },
    { name: "Unknown", value: unknownPercent },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
        p: 2,
        background: Color.WHITE,
        borderRadius: "var(--Number-Scale-2s-12, 12px)",
        boxShadow: "0px 8px 24px -3px rgba(16, 24, 40, 0.10), 0px 8px 24px -3px rgba(16, 24, 40, 0.05)",
      }}
    >
      <StatWithProgress
        label="Admission Rate"
        value={provider.openAdmin ? 1 : provider.admissionPercent || 0}
        variant="progress"
      />
      <StatWithProgress
        label="Completion Rate"
        value={provider.completionRate || 0}
        variant="progress"
      />
      <StatWithProgress
        label="Median ACT Range"
        range={{ min: provider.act25th || 0, max: provider.act75th || 0 }}
        variant="range"
      />
      <StatDetail
        label="Total Size"
        value={
          provider.undergradTotalEnrollment
            ? numberToRound({ number: provider.undergradTotalEnrollment })
            : "Unknown"
        }
      />
      <StatDetail
        label="Religious Affiliation"
        value={
          provider.religiousAffiliation &&
          provider.religiousAffiliation !== "null"
            ? provider.religiousAffiliation
            : "None"
        }
      />
      <StatDetail
        label="Single Gender"
        value={
          provider.menOnly ? "Men Only" : provider.womenOnly ? "Women Only" : "No"
        }
      />
      <DiversityChart data={raceAndEthnicityDiversityData} />
    </Box>
  );
};

const StatWithProgress = ({
  label,
  value,
  range,
  variant = "progress",
}: {
  label: string;
  value?: number;
  range?: { min: number; max: number };
  variant: "progress" | "range";
}) => {
  const percentValue = value ? value * 100 : 0;
  const percentLabel = value ? numberToPercent({ number: value }) : "Unknown";
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Typography
        sx={{ flex: "none", width: "150px", fontSize: 14, fontWeight: 700, color: Color.CHARCOAL }}
      >
        {label}:
      </Typography>
      {variant === "progress" && (
        <Box sx={{ position: "relative", width: "100%" }}>
          <LinearProgress
            variant="determinate"
            value={percentValue}
            sx={{
              width: "100%",
              height: "28px",
              borderRadius: "4px",
              backgroundColor: Color.SOFT_GRAY,
              "& .MuiLinearProgress-bar": {
                background: Color.MINT,
                borderRadius: "4px",
              },
            }}
          />
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: percentValue < 50 ? Color.DARK_TEAL : Color.WHITE,
              fontSize: 12,
              fontWeight: 700,
            }}
          >
            {percentLabel}
          </Typography>
        </Box>
      )}
      {variant === "range" && range && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "28px",
            borderRadius: "4px",
            backgroundColor: Color.SOFT_GRAY,
            position: "relative",
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              left: 4,
              color: Color.DARK_SOFT_GRAY,
              fontWeight: 700,
            }}
          >
            1
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "100px",
              width: `${((range.max - range.min) / 36) * 100}%`,
              height: "100%",
              backgroundColor: Color.MINT,
              borderRadius: "4px",
              color: Color.WHITE,
              fontWeight: "bold",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: Color.GRAY_50,
                fontSize: 12,
                fontWeight: 700,
              }}
            >
              {range.min === 0 || range.max === 0 ? "Unknown" : `${range.min}-${range.max}`}
            </Typography>
          </Box>
          <Typography
            sx={{
              position: "absolute",
              right: 4,
              color: Color.DARK_SOFT_GRAY,
              fontWeight: 700,
            }}
          >
            36
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const StatDetail = ({ label, value }: { label: string; value: string }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    <Typography
      sx={{
        flex: "none",
        width: "150px",
        fontSize: 14,
        fontWeight: 700,
        color: Color.CHARCOAL,
      }}
    >
      {label}:
    </Typography>
    <Typography
      sx={{
        fontSize: 14,
        fontWeight: 400,
        color: Color.GRAY_800,
      }}
    >
      {value}
    </Typography>
  </Box>
);

const DiversityChart = ({ data }: { data: any[] }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    <Typography
      sx={{
        flex: "none",
        width: "150px",
        fontSize: 14,
        fontWeight: 700,
        color: Color.CHARCOAL,
      }}
    >
      Race & ethnicity diversity:
    </Typography>
    <PieChart width={188} height={188}>
      <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={45} outerRadius={90}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
    </PieChart>
  </Box>
);

export default ProviderStats;
