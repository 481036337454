import { Box, Button, Chip, Grid, Paper, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Color } from "../../../shared/types/enums";
import { ProviderProgramRecord } from "../../../shared/types/types";
import Reactions from "./Reactions";
import ProgramDescription from "./ProgramDescription";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import { formatCurrency, formatProgramName } from "../../../shared/utils/formatUtils";
import WhatIsROIDialog from "../../../shared/components/whatIsROI/WhatIsROIDialog";
import WhatIsRTSSchool from "../../../shared/components/whatIsRTS/WhatIsRTSDialog";
import QualityMeasures from "./QualityMeasures";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import infoIcon from "../../../shared/assets/icons/info.svg";
import useProgramCard from "../../hooks/programCard/useProgramCard";
import AutoAdmit from "./AutoAdmit";

type Props = {
  program: ProviderProgramRecord;
  showDeleteButton?: boolean;
  setSelectedProgram?: Dispatch<SetStateAction<ProviderProgramRecord | null>>;
  setRecommendOpen?: Dispatch<SetStateAction<boolean>>;
  setSelectedLearnMoreProgram?: Dispatch<SetStateAction<ProviderProgramRecord | null>>;
};

const ProgramCard = ({ program, showDeleteButton = true, setSelectedLearnMoreProgram }: Props) => {
  const { renderDuration, lowIncome } = useProgramCard(program);
  const [whatIsROIOpen, setWhatIsROIOpen] = useState(false);
  const [whatIsRTSSchoolOpen, setWhatIsRTSSchoolOpen] = useState(false);
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const [personalizedCost, setPersonalizedCost] = useState<number | null>(null);

  const handleLearnMore = () => {
    if (!setSelectedLearnMoreProgram) return;
    setSelectedLearnMoreProgram(program);
  };

  const formatCredentialType = (credLevel: number | null) => {
    if (credLevel === 1) {
      return "Certificate / Training";
    } else if (credLevel === 2) {
      return "Associate's Degree";
    } else if (credLevel === 3) {
      return "Bachelor's Degree";
    } else {
      return "Certificate / Training";
    }
  };

  type Props = {
    gpaValue: number | null;
    gpaMax: number | null;
    act25th: number | null;
    act75th: number | null;
    openAdmin: boolean;
    admissionPercent: number | null;
    act: string | null;
  };

  const generateAccessLevel = ({
    gpaValue,
    gpaMax,
    act25th,
    act75th,
    openAdmin,
    admissionPercent,
    act,
  }: Props) => {
    // Ensure GPA percentage is within the valid range (0 to 100)
    if (openAdmin) return "Safety";
    const actNumber = act ? parseInt(act) : null;
    const gpaPercent = gpaValue && gpaMax ? gpaValue / gpaMax : null;
    if ((!gpaPercent || gpaPercent < 0 || gpaPercent > 1) && !act) {
      return "Unknown";
    }
    if ((!act25th || !act75th) && !admissionPercent) {
      return "Unknown";
    }

    type ConversionTableRow = {
      gpaPercent: number;
      act: number;
      safetyCutoff: number;
      reachCutoff: number;
    };

    // Define the conversion table
    const conversionTable: ConversionTableRow[] = [
      { gpaPercent: 36 / 36, act: 36, safetyCutoff: 0.5, reachCutoff: 0.1 },
      { gpaPercent: 35 / 36, act: 35, safetyCutoff: 0.5, reachCutoff: 0.15 },
      { gpaPercent: 34 / 36, act: 34, safetyCutoff: 0.55, reachCutoff: 0.15 },
      { gpaPercent: 33 / 36, act: 33, safetyCutoff: 0.55, reachCutoff: 0.2 },
      { gpaPercent: 32 / 36, act: 32, safetyCutoff: 0.55, reachCutoff: 0.2 },
      { gpaPercent: 31 / 36, act: 31, safetyCutoff: 0.55, reachCutoff: 0.25 },
      { gpaPercent: 30 / 36, act: 30, safetyCutoff: 0.6, reachCutoff: 0.25 },
      { gpaPercent: 29 / 36, act: 29, safetyCutoff: 0.6, reachCutoff: 0.3 },
      { gpaPercent: 28 / 36, act: 28, safetyCutoff: 0.6, reachCutoff: 0.3 },
      { gpaPercent: 27 / 36, act: 27, safetyCutoff: 0.6, reachCutoff: 0.35 },
      { gpaPercent: 26 / 36, act: 26, safetyCutoff: 0.65, reachCutoff: 0.35 },
      { gpaPercent: 25 / 36, act: 25, safetyCutoff: 0.65, reachCutoff: 0.4 },
      { gpaPercent: 24 / 36, act: 24, safetyCutoff: 0.65, reachCutoff: 0.45 },
      { gpaPercent: 23 / 36, act: 23, safetyCutoff: 0.65, reachCutoff: 0.5 },
      { gpaPercent: 22 / 36, act: 22, safetyCutoff: 0.7, reachCutoff: 0.55 },
      { gpaPercent: 21 / 36, act: 21, safetyCutoff: 0.7, reachCutoff: 0.6 },
      { gpaPercent: 20 / 36, act: 20, safetyCutoff: 0.75, reachCutoff: 0.7 },
      { gpaPercent: 19 / 36, act: 19, safetyCutoff: 0.75, reachCutoff: 0.7 },
      { gpaPercent: 18 / 36, act: 18, safetyCutoff: 0.8, reachCutoff: 0.7 },
      { gpaPercent: 17 / 36, act: 17, safetyCutoff: 0.8, reachCutoff: 0.75 },
      { gpaPercent: 16 / 36, act: 16, safetyCutoff: 0.85, reachCutoff: 0.8 },
      { gpaPercent: 15 / 36, act: 15, safetyCutoff: 0.85, reachCutoff: 0.85 },
      { gpaPercent: 14 / 36, act: 14, safetyCutoff: 0.9, reachCutoff: 0.9 },
      { gpaPercent: 13 / 36, act: 13, safetyCutoff: 0.9, reachCutoff: 0.9 },
      { gpaPercent: 12 / 36, act: 12, safetyCutoff: 0.95, reachCutoff: 0.9 },
      { gpaPercent: 11 / 36, act: 11, safetyCutoff: 0.95, reachCutoff: 0.9 },
    ];

    // Find the closest GPA percentage in the conversion table
    let closestGpaPercent: ConversionTableRow | null = null;
    if (gpaPercent && gpaPercent > 0 && gpaPercent < 1) {
      closestGpaPercent = conversionTable.reduce((prev, curr) =>
        Math.abs(curr.gpaPercent - gpaPercent) < Math.abs(prev.gpaPercent - gpaPercent)
          ? curr
          : prev
      );
    }

    if (actNumber && act25th && act75th) {
      if (actNumber < act25th) {
        return "Reach";
      } else if (actNumber >= act25th && actNumber <= act75th) {
        return "Target";
      } else if (actNumber > act75th) {
        return "Safety";
      } else {
        return "Unknown";
      }
    }
    if (closestGpaPercent && act25th && act75th) {
      if (closestGpaPercent.act < act25th) {
        return "Reach";
      } else if (closestGpaPercent.act >= act25th && closestGpaPercent.act <= act75th) {
        return "Target";
      } else if (closestGpaPercent.act > act75th) {
        return "Safety";
      } else {
        return "Unknown";
      }
    }
    if ((!act25th || !act75th) && admissionPercent && closestGpaPercent) {
      if (admissionPercent > closestGpaPercent.safetyCutoff) {
        return "Safety";
      } else if (
        admissionPercent <= closestGpaPercent.safetyCutoff &&
        admissionPercent >= closestGpaPercent.reachCutoff
      ) {
        return "Target";
      } else if (admissionPercent < closestGpaPercent.reachCutoff) {
        return "Reach";
      } else {
        return "Unknown";
      }
    }

    return "Unknown";
  };

  const calculateStartingSalary = (program: ProviderProgramRecord) => {
    if (program && program.program1yrEarnings) {
      return formatCurrency({ amount: program.program1yrEarnings });
    } else if (program && program.provider1yrEarnings) {
      return formatCurrency({ amount: program.provider1yrEarnings });
    } else {
      return "Unknown";
    }
  };

  useEffect(() => {
    const getPersonalizedCost = async () => {
      if (!loggedInStudent || personalizedCost !== null) return;
      if ((lowIncome && program.autoScholarship) || program.totalProgramCost === 0) {
        setPersonalizedCost(0);
        return;
      }
      if (program.providerId.length > 8) {
        setPersonalizedCost(null);
        return;
      }
      try {
        if (!loggedInStudent.efc) {
          setPersonalizedCost(null);
          return;
        }
        const dataToSubmit = {
          school: {
            ipedsId: program.providerId,
          },
          student: {
            address: {
              city: loggedInStudent.address.city,
              state: loggedInStudent.address.state,
              zip: loggedInStudent.address.zip,
            },
            profile: {
              efc: Math.round(loggedInStudent.efc ? loggedInStudent.efc : 0),
              act: loggedInStudent.act ? loggedInStudent.act : undefined,
              sat: loggedInStudent.sat
                ? Math.round(parseInt(loggedInStudent.sat) / 10) * 10
                : undefined,
              gpa: {
                highSchool:
                  loggedInStudent.gpaValue && loggedInStudent.gpaMax
                    ? (loggedInStudent.gpaValue * 4) / loggedInStudent.gpaMax
                    : undefined,
              },
            },
          },
        };
        const response = await fetch("https://api.tuitionfit.org/pricing/v0", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": "XKgOM0V7wW5UJKkUiJsRE4f5txUq1Ums4mFKOI2g",
          },
          body: JSON.stringify(dataToSubmit),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const cost = data.prices.lower + (data.prices.upper - data.prices.lower) / 2;
        setPersonalizedCost(cost);
      } catch (error) {
        console.error("Error fetching personalized cost:", error);
      }
    };
    getPersonalizedCost();
  }, [
    loggedInStudent,
    lowIncome,
    personalizedCost,
    program.autoScholarship,
    program.providerId,
    program.totalProgramCost,
  ]);

  return (
    <>
      {program && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {/* Custom boarder & messsage around programs that have auto-admit turned on */}
          <AutoAdmit program={program}>
            <Paper
              sx={{
                backgroundColor: Color.WHITE,
                color: Color.CHALKBOARD,
                pt: 2,
                maxWidth: 450,
                borderRadius: 6,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Chip
                  label={formatCredentialType(program.credLevel)}
                  sx={{ fontSize: 11, ml: 1, backgroundColor: Color.SURFACE_GREEN }}
                />
                <Reactions showDeleteButton={showDeleteButton} programId={program.id} />
              </Box>
              <Box sx={{ padding: 0, ml: 2, mr: 2 }}>
                <Typography
                  component="h6"
                  variant="cardProviderName"
                >{`${program.providerName}`}</Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FeatherIcon icon="location" width="16px" />
                  <Typography
                    component="div"
                    variant="cardLocation"
                  >{`${program.city}, ${program.state}`}</Typography>
                </Box>
                <Typography variant="h4">
                  {formatProgramName({ name: program.programName })}
                </Typography>
                <Box>
                  {loggedInStudent?.willowRecommendedProgramIds.includes(program.id) && (
                    <Box
                      sx={{
                        mt: 1,
                        borderRadius: 12,
                        backgroundColor: Color.MINT,
                        display: "inline-block",
                        p: 1,
                      }}
                    >
                      <Typography sx={{ color: Color.BLACK }} variant="body2">
                        Willow Recommended
                      </Typography>
                    </Box>
                  )}
                  {loggedInStudent?.staffRecommendedProgramIds.includes(program.id) && (
                    <Box
                      sx={{
                        mt: 1,
                        borderRadius: 12,
                        backgroundColor: Color.ORANGE_700,
                        display: "inline-block",
                        p: 1,
                        ml: 1,
                      }}
                    >
                      <Typography sx={{ color: Color.BLACK }} variant="body2">
                        Staff Recommended
                      </Typography>
                    </Box>
                  )}
                </Box>
                {program.programShortDescription && (
                  <Typography variant="body1" sx={{ color: Color.CHALKBOARD, mt: 1 }}>
                    <ProgramDescription description={program.programShortDescription} />
                  </Typography>
                )}
              </Box>
              <Box sx={{ pl: 2, pr: 2, pb: 1, ml: 2, mr: 2, pt: 1 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 600 }} variant="h6">
                      {(lowIncome && program.autoScholarship) || program.totalProgramCost === 0
                        ? "Free"
                        : personalizedCost !== null
                        ? formatCurrency({ amount: personalizedCost })
                        : program.totalProgramCost !== null
                        ? formatCurrency({ amount: program.totalProgramCost })
                        : "Unknown"}
                    </Typography>
                    {personalizedCost !== null && (
                      <>
                        <Typography variant="body2" sx={{ mt: "-2px" }}>
                          This cost is personalized based on all your information.
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 600, color: 'grey', mt: "-2px" }}>
                        {formatCurrency({ amount: program.totalProgramCost! })}
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'grey', mt: "-2px" }}>
                        Total Estimated Cost without financial aid
                        </Typography>
                      </>
                    )}
                    {program.totalProgramCost !== null && personalizedCost === null && (
                      <Typography variant="body1" sx={{ mt: "-2px" }}>
                        Total Estimated Cost
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 600 }} variant="h6">
                      {calculateStartingSalary(program)}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: "-2px" }}>
                      Average starting salary
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 600 }} variant="h6">
                      {renderDuration({
                        months: program?.durationMonths ?? 0,
                        years: program?.durationYears ?? 0,
                      })}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: "-2px" }}>
                      Length of program
                    </Typography>
                  </Grid>
                  {loggedInStudent && (
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontWeight: 600 }} variant="h6">
                        {generateAccessLevel({
                          gpaValue: loggedInStudent.gpaValue,
                          gpaMax: loggedInStudent.gpaMax,
                          act25th: program.act25th,
                          act75th: program.act75th,
                          openAdmin: program.openAdmin,
                          admissionPercent: program.admissionPercent,
                          act: loggedInStudent.act,
                        })}
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body1">Fit</Typography>
                        <img
                          src={infoIcon}
                          alt="info"
                          width="12px"
                          height="12px"
                          style={{ cursor: "pointer", marginLeft: "4px" }}
                          onClick={() => setWhatIsRTSSchoolOpen(true)}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <QualityMeasures
                program={program}
                setWhatIsROIOpen={setWhatIsROIOpen}
                personalizedCost={personalizedCost}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  py: 2,
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Button sx={{ px: 1, py: 0.5 }} variant="outlined" onClick={handleLearnMore}>
                    Learn More
                  </Button>
                </Box>
              </Box>
            </Paper>
          </AutoAdmit>
        </Box>
      )}

      <WhatIsROIDialog open={whatIsROIOpen} setOpen={setWhatIsROIOpen} />
      <WhatIsRTSSchool open={whatIsRTSSchoolOpen} setOpen={setWhatIsRTSSchoolOpen} />
    </>
  );
};

export default ProgramCard;
