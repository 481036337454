import { Box, Grid, Typography } from "@mui/material";
import { JobDetailsRecord } from "../../types/types";
import useJobs from "../../hooks/exploreCareers/useJobs";
import { useRecoilValue } from "recoil";
import { userTypeAtom } from "../../recoil/userAtoms";
import { UserType } from "../../types/enums";
import StudentJobCard from "../../../students/components/exploreCareers/StudentJobCard";
import StaffJobCard from "../../../staff/components/exploreCareers/StaffJobCard";
import { selectedFieldIdAtom } from "../../recoil/exploreCareersAtoms";

type Props = {
  jobs: JobDetailsRecord[];
};

const Jobs = ({ jobs }: Props) => {
  const selectedFieldId = useRecoilValue(selectedFieldIdAtom);
  const { mobile, field, filteredJobs } = useJobs({ selectedFieldId, jobs });
  const userType = useRecoilValue(userTypeAtom);

  // const filteredJobs = jobs.filter((job) => job.industry_sub_id === selectedFieldId);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
      <Typography variant="h4" fontSize={mobile ? 24 : 30}>
        {field?.title}
      </Typography>
      <Typography variant="h5" sx={{ mt: 2 }}>
        Careers
      </Typography>
      <Grid container alignContent="start" spacing="20px">
        {filteredJobs.map((job, index: number) => (
          <Grid key={index} item xs={12} sm={6}>
            {userType === UserType.STUDENT ? (
              <StudentJobCard job={job} />
            ) : (
              <StaffJobCard job={job} />
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Jobs;
