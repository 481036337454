import { Dispatch, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import { Collection, FunctionName, GradeLevel } from "../../../shared/types/enums";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { LogEventType } from "../../../shared/types/logEnums";
import { AddressComponents } from "../../../shared/types/types";
import * as Yup from "yup";
import { allSchoolsAtom } from "../../../shared/recoil/schoolAndDistrictAtoms";
import { fetchData } from "../../../shared/utils/fetchUtils";

type Props = {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  phone: Yup.string().required("Phone Number is required"),
  schoolId: Yup.string().required("School is required"),
  gradeLevel: Yup.string().required("Grade Level is required"),
  address: Yup.object({
    address: Yup.string(),
    lat: Yup.number().required(),
    lon: Yup.number().required(),
  }).required(),
  externalId: Yup.string().notRequired()
});

type FormType = {
  firstName: string;
  lastName: string;
  phone: string;
  address: AddressComponents;
  externalId: string;
  schoolId: string;
  gradeLevel: GradeLevel;
};

const useEditStudentCardDialog = ({ setOpen }: Props) => {
  const [loading, setLoading] = useState(false);
  const [loggedInStudent, setLoggedInStudent] = useRecoilState(loggedInStudentAtom);
  const { updateFSDoc } = useUpdateFSDoc();
  const { submitLog } = useLogger();
  const schools = useRecoilValue(allSchoolsAtom);
  const gradeLevels: GradeLevel[] = Object.values(GradeLevel).map((value) => value);

  const initialValues: FormType = {
    firstName: loggedInStudent?.firstName || "",
    lastName: loggedInStudent?.lastName || "",
    phone: loggedInStudent?.phone || "",
    schoolId: loggedInStudent?.schoolId || "",
    externalId: loggedInStudent?.externalId || "",
    gradeLevel: (loggedInStudent?.gradeLevel as GradeLevel) || "",
    address: loggedInStudent?.address || {
      address: "",
      lat: 0,
      lon: 0,
    },
  };

  const handleSave = async (values: FormType) => {
    const selectedSchool = schools.find((school) => school.id === values.schoolId);
    const schoolId = selectedSchool?.id || null;
    const districtId = selectedSchool?.districtId || null;
    if (!loggedInStudent) return;
    setLoading(true);
    try {
      await updateFSDoc({
        col: Collection.STUDENTS,
        data: { ...values, schoolId, districtId },
        id: loggedInStudent.id,
      });
      if (loggedInStudent.schoolId !== schoolId) {
        await fetchData({
          functionName: FunctionName.UPDATE_SCHOOL_AND_DISTRICT_ID,
          payload: {
            studentId: loggedInStudent.id,
            newSchoolId: schoolId,
            newDistrictId: districtId,
          },
        });
      }
      setLoggedInStudent({ ...loggedInStudent, ...values, schoolId, districtId });
      submitLog({
        eventType: LogEventType.USER_UPDATED_PROFILE,
        file: "useEditStudentCardDialog.ts",
      });
      setOpen(false);
    } catch (error: any) {
      submitLog({
        error,
        snackbarMessage: "There was an error updating the profile, please refresh and try again.",
        eventType: LogEventType.USER_UPDATE_PROFILE_ERROR,
        file: "useEditStudentCardDialog.ts",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    loading,
    initialValues,
    validationSchema,
    gradeLevels,
    handleSave,
    handleClose,
  };
};

export default useEditStudentCardDialog;
