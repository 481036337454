import { useEffect, useState, useCallback } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { ProviderProgramRecord, ReviewRecord } from "../../types/types";
import useGetFSDoc from "../db/useGetFSDoc";
import { userTypeAtom } from "../../recoil/userAtoms";
import { Collection, FunctionName, UserType } from "../../types/enums";
import { allMatchedProgramsAtom, programsAtom } from "../../recoil/providerAndProgramAtom";
import { fetchData } from "../../utils/fetchUtils";
import { getProgramImage } from "../../utils/imageUtils";
import { nationalDB } from "../../../firebase";
import { collection, doc, getDoc } from "firebase/firestore";

type Props = {
  programId: string;
};

const useProgramDetailsDialog = ({ programId }: Props) => {
  const [whatIsROIOpen, setWhatIsROIOpen] = useState(false);
  const [currentProgram, setCurrentProgram] = useState<ProviderProgramRecord | null>(null);
  const [applyLink, setApplyLink] = useState<string | null>(null);
  const [getProgramTrigger, setGetProgramTrigger] = useState(false);
  const [loading, setLoading] = useState(true); // New loading state
  const { getFSDoc } = useGetFSDoc();
  const [reviews, setReviews] = useState<ReviewRecord | null>(null);
  const programs = useRecoilValue(programsAtom);
  const allMatchedPrograms = useRecoilValue(allMatchedProgramsAtom);
  const userType = useRecoilValue(userTypeAtom);
  const navigate = useNavigate();
  const [programImage, setProgramImage] = useState<string | undefined>(undefined);

  const loadImage = useCallback(async (willowSubIndustryId: string | undefined | null) => {
    if (!willowSubIndustryId) return;
    try {
      const imageModule = await getProgramImage({ id: willowSubIndustryId });
      setProgramImage(imageModule.default);
    } catch (error) {
      console.error("Error loading image:", error);
      setProgramImage(undefined);
    }
  }, []);

  const fetchProgram = useCallback(
    async (programId: string) => {
      setLoading(true); // Start loading
      try {
        const now = new Date().getTime();
        const results = await fetchData({
          functionName: FunctionName.GET_PROGRAM_AND_PROVIDER_BY_ID,
          payload: { documentId: programId },
        });
        const { program } = (await results.json()) as { program: ProviderProgramRecord };
        setCurrentProgram(program || null);
        loadImage(program?.willowSubIndustryId);
      } catch (error) {
        console.error("Error fetching program:", error);
      } finally {
        setLoading(false); // Stop loading after the fetch
      }
    },
    [loadImage]
  );

  useEffect(() => {
    if (!currentProgram) return;
    const getReviews = async () => {
      const colRef = collection(nationalDB, Collection.REVIEWS);
      const docRef = doc(colRef, currentProgram.providerId);
      const snapshot = await getDoc(docRef);
      const results = { ...snapshot.data(), id: docRef.id } as ReviewRecord;
      setReviews(results);
    };
    getReviews();
  }, [currentProgram, getFSDoc]);

  useEffect(() => {
    const currentPrograms = userType === UserType.STUDENT ? programs : allMatchedPrograms;
    const selectedProgram = currentPrograms.find((program) => program.id === programId);
    if (selectedProgram) {
      setCurrentProgram(selectedProgram);
      setLoading(false); // Program found in memory, stop loading
    } else {
      fetchProgram(programId);
    }
  }, [allMatchedPrograms, programs, programId, userType, fetchProgram, getProgramTrigger]);

  useEffect(() => {
    if (!currentProgram) return;
    const { applyURL, homeURL } = currentProgram;
    const validLink = (url: string) => (url.includes("http") ? url : `https://${url}`);
    if (applyURL && applyURL.length > 3) {
      setApplyLink(validLink(applyURL));
    } else if (homeURL && homeURL.length > 3) {
      setApplyLink(validLink(homeURL));
    }
  }, [currentProgram]);

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const openWhatIsROI = useCallback(() => {
    setWhatIsROIOpen(true);
  }, []);

  return {
    currentProgram,
    navigateBack,
    openWhatIsROI,
    whatIsROIOpen,
    setWhatIsROIOpen,
    applyLink,
    setGetProgramTrigger,
    reviews,
    programImage,
    loading, // Return loading state
  };
};

export default useProgramDetailsDialog;
