import { Box, MenuList, Typography } from "@mui/material";
import exploreCareersGraphic from "../../../shared/assets/static/explore-careers-graphic.svg";
// import { useSetRecoilState } from "recoil";
// import {
//   selectedFieldIdAtom,
//   selectedIndustryIdAtom,
//   selectedJobIdAtom,
// } from "../../../shared/recoil/exploreCareersAtoms";

type _Props = {
  selectedMenuItem: "all" | "recommended";
  setSelectedMenuItem: (selectedMenuItem: "all" | "recommended") => void;
};

const ExploreScholarshipsSidebar = () => {
  // const setSelectedJobId = useSetRecoilState(selectedJobIdAtom);
  // const setSelectedFieldId = useSetRecoilState(selectedFieldIdAtom);
  // const setSelectedIndustryId = useSetRecoilState(selectedIndustryIdAtom);

  // const resetNavigation = () => {
  //   setSelectedJobId(null);
  //   setSelectedFieldId(null);
  //   setSelectedIndustryId(null);
  //   setSelectedMenuItem("all");
  // };

  return (
    <>
      <Box sx={{ pt: 1 }}>
        <Typography variant="h4">Scholarships</Typography>
        <Box
          sx={{
            pl: 2,
            height: "calc(100VH - 135px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <MenuList>
            {/* <MenuItem
              selected={selectedMenuItem === "all"}
              onClick={resetNavigation}
              style={{
                padding: "16px",
                borderBottom: "1px solid #E0E0E0",
                fontSize: "16px",
                fontWeight: selectedMenuItem === "all" ? "bold" : "normal",
                borderLeft: selectedMenuItem === "all" ? `4px solid ${Color.MINT}` : "none",
              }}
            >
              All
            </MenuItem>
            <MenuItem
              selected={selectedMenuItem === "recommended"}
              onClick={() => setSelectedMenuItem("recommended")}
              style={{
                padding: "16px",
                borderBottom: "1px solid #E0E0E0",
                fontSize: "16px",
                fontWeight: selectedMenuItem === "recommended" ? "bold" : "normal",
                borderLeft: selectedMenuItem === "recommended" ? `4px solid ${Color.MINT}` : "none",
              }}
            >
              Recommended
            </MenuItem> */}
          </MenuList>
          <Box>
            <img src={exploreCareersGraphic} alt="explore-careers" width="100%" />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ExploreScholarshipsSidebar;
