import { Box, Paper, Typography } from "@mui/material";
import AlmaIcon from "../../../shared/assets/icons/AlmaIcon";
import { Player } from "@lottiefiles/react-lottie-player";
import animatedAlma from "../../../shared/assets/icons/animatedAlma.json";
import CloseIcon from "../closeIcon/CloseIcon";

const InfoBox = ({ message, onClose }: { message: string; onClose?: () => void }) => {
  return (
    <Paper sx={{ pl: 3, py: 3, pr: 1 }}>
      <Box sx={{ display: "flex", alignItems: "start" }}>
        <Box sx={{ pr: 2, pt: 1, flexShrink: 0 }}>
          {process.env.REACT_APP_DISABLE_LOTTIE === "true" ? (
            <AlmaIcon size={30} />
          ) : (
            <Player autoplay loop src={animatedAlma} style={{ height: "30px" }} />
          )}
        </Box>
        <Box>
          <Typography variant="body1">{message}</Typography>
        </Box>
        {onClose && (
          <Box sx={{ mt: -1 }}>
            <CloseIcon onClose={onClose} size={24} />
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default InfoBox;
