import { IconButton } from "@mui/material";
import React from "react";
import { Color } from "../../types/enums";

const CloseIcon = ({ onClose, size }: { onClose: () => void; size?: number }) => {
  return (
    <IconButton aria-label="close" onClick={onClose} sx={{ color: Color.GRAY_800, mx: -1 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size || 40}
        height={size || 40}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="15" y1="9" x2="9" y2="15"></line>
        <line x1="9" y1="9" x2="15" y2="15"></line>
      </svg>
    </IconButton>
  );
};

export default CloseIcon;
