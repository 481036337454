import { Box, Card, CardContent, Typography } from "@mui/material";
import { Color, QuizResultsOption } from "../../types/enums";
import { PersonalityTypeRecord } from "../../types/types";
import NavigationButtons from "./NavigationButtons";
import { useEffect, useState } from "react";
import sectionImage from "../../assets/profileImages/sections/your-work-style.svg";

type Props = {
  mobile: boolean;
  personalityType: PersonalityTypeRecord;
  onActionButtonClick: (value: QuizResultsOption) => void;
};

const YourWorkStyle = ({
  mobile,
  personalityType,
  onActionButtonClick,
}: Props) => {
  const [environments, setEnvironments] = useState<string[]>([]);

  useEffect(() => {
    const extracts = extractEnvironments(personalityType.workStyle);
    setEnvironments(extracts);
  }, [personalityType.workStyle]);

  const extractEnvironments = (text: string): string[] => {
    // Case 1: "Ideal work environments include ..."
    const match1 = text.match(/Ideal work environments include ([^.]+)\./);

    if (match1) {
        const environmentsText = match1[1];
        const parts = environmentsText.split(", ");
        const environments = parts.map((part) => part.replace(/^or /, "").trim());
        if (environments.length > 0) {
            environments[environments.length - 1] = environments[
                environments.length - 1
            ]
                .replace(/^and /, "")
                .trim();
        }
        return environments;
    }

    // Case 2: "For example, ... would be ideal scenarios" or "would be an ideal scenarios."
    const match2 = text.match(/For example, (.+?) would be (?:an )?ideal scenarios?(?: for you)?\./);

    if (match2) {
        const scenariosText = match2[1];
        const scenarios = scenariosText.split(/ and | or |, /).map((scenario) => scenario.trim());
        return scenarios;
    }

    return [];
  };

  const getTextExcludingLastSentence = (text: string): string => {
    const sentences = text.match(/[^.!?]+[.!?]/g);
    if (sentences && sentences.length > 1) {
      return sentences.slice(0, -1).join(" ").trim();
    }
    return "";
  };

  return (
    <Box>
      <Typography
        sx={{ mt: 2, fontWeight: 600 }}
        variant="h6"
        fontSize={16}
        color={Color.ORANGE_700}
      >
        {personalityType.title}
      </Typography>
      <Typography variant="h3" fontSize={30} color={Color.ORANGE_700}>
        {QuizResultsOption.YOUR_WORK_STYLE}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <img
          style={{ width: "100%", maxWidth: mobile ? "260px" : "480px" }}
          src={sectionImage}
          alt={personalityType.title}
        />
      </Box>
      <Typography
        sx={{ mt: 2 }}
        variant="body1"
        fontSize={14}
        color={Color.CHALKBOARD}
      >
        {environments.length > 0
          ? getTextExcludingLastSentence(personalityType.workStyle)
          : personalityType.workStyle}
      </Typography>
      {environments.length > 0 && (
        <Card
          sx={{
            mt: 4,
            borderRadius: "12px",
            padding: 2,
            boxShadow: "0px 6px 15px -2px #1018280F",
          }}
        >
          <CardContent>
            <Typography variant="h5" fontSize={20} color={Color.CHALKBOARD}>
              Ideal work environments
            </Typography>
            <Box sx={{ mt: 1, display: "flex", flexDirection: "column" }}>
              {environments.map((environment, index) => (
                <Box
                  key={index}
                  sx={{
                    py: "5px",
                    borderBottom: 1,
                    borderColor: Color.STANDARD_GRAY,
                  }}
                >
                  <Typography fontSize={14} color={Color.CHALKBOARD}>
                    {environment.charAt(0).toUpperCase() +
                      environment.slice(1)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      )}
      <NavigationButtons
        mobile={mobile}
        currentTitle={QuizResultsOption.YOUR_WORK_STYLE}
        onNextClick={(nextTitle) => onActionButtonClick(nextTitle)}
        onPreviousClick={(nextTitle) => onActionButtonClick(nextTitle)}
      />
    </Box>
  );
};

export default YourWorkStyle;
