import React from "react";
import { Dialog, DialogContent, Typography, Grid, Button, Chip, Box, Divider, IconButton } from "@mui/material";
import { ScholarshipApplication, ScholarshipApplicationRecord, ScholarshipRecord } from "../../../shared/types/types";
import { Collection, Color } from "../../../shared/types/enums";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import { formatCurrency, formatDate } from "../../../shared/utils/formatUtils";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import useAddFSDoc from "../../../shared/hooks/db/useAddFSDoc";

const generateBlankScholarshipApplication = ({
  scholarshipId,
  studentId,
  schoolId,
  districtId,
}: {
  scholarshipId: string;
  studentId: string;
  schoolId: string;
  districtId: string;
}): ScholarshipApplication => {
  return {
    scholarshipId,
    studentId,
    status: "applied",
    appliedDate: null,
    awardAmount: null,
    awardDate: null,
    schoolId,
    districtId,
  };
};

type Props = {
  scholarship: ScholarshipRecord | null;
  scholarshipApplication: ScholarshipApplicationRecord | null;
  setScholarshipApplications: React.Dispatch<React.SetStateAction<ScholarshipApplicationRecord[]>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const formatAward = (minAward: number | null, maxAward: number | null) => {
  const awardAmount =
    minAward && maxAward
      ? `${formatCurrency({ amount: minAward })} - ${formatCurrency({
          amount: maxAward,
        })}`
      : minAward
      ? `${formatCurrency({ amount: minAward })}`
      : maxAward
      ? `${formatCurrency({ amount: maxAward })}`
      : null;
  return awardAmount || "Not Yet Set";
};

const CardChip = (props: { label: string }) => (
  <Chip label={props.label} color="lightGreen" sx={{ color: Color.DARK_GREEN, fontSize: 12, p: 0, height: 22 }} />
);

const ScholarshipDetailsDialog: React.FC<Props> = ({
  scholarship,
  scholarshipApplication,
  setScholarshipApplications,
  setOpen,
}) => {
  const { addFSDoc } = useAddFSDoc();
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);

  const handleUpdateStatus = async () => {
    if (
      !loggedInStudent ||
      !loggedInStudent.schoolId ||
      !loggedInStudent.districtId ||
      !scholarship
    )
      return;
    if (!scholarshipApplication) {
      const blankApplication = generateBlankScholarshipApplication({
        scholarshipId: scholarship.id,
        studentId: loggedInStudent.id,
        schoolId: loggedInStudent.schoolId,
        districtId: loggedInStudent.districtId,
      });
      const results = await addFSDoc<ScholarshipApplicationRecord>({
        col: Collection.SCHOLARSHIP_APPLICATIONS,
        data: blankApplication,
      });
      if (!results) return;
      setScholarshipApplications((prev) => [...prev, results]);
      setOpen(false);
    }
  };

  if (!scholarship) return null;

  return (
    <Dialog open={true} fullWidth maxWidth="md">
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color: Color.GRAY_800,
          backgroundColor: Color.WHITE,
        }}
      >
        <FeatherIcon icon="close" strokeWidth="1" />
      </IconButton>
      <DialogContent>
        <Box sx={{ p: 0, display: "flex", flexDirection: "column" }}>
          {scholarship.sponsorName && (
            <Typography variant="h6" sx={{ fontSize: 12, color: Color.MINT }}>
              {scholarship.sponsorName}
            </Typography>
          )}
          <Typography variant="h4" sx={{ fontSize: 20, lineHeight: "30px" }}>
            {scholarship.name}
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.25, mt: 1.25 }}>
            {scholarship.tags.length > 0 &&
              scholarship.tags.map((tag) => <CardChip key={tag} label={tag} />)}
          </Box>
          <Box sx={{ mt: 1.5 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <FeatherIcon icon="menu-book" fillColor={Color.DARK_MINT} strokeWidth="0" />
              <Typography
                variant="h6"
                sx={{
                  color: Color.CHALKBOARD,
                  fontSize: 16,
                  lineHeight: "19.2px",
                }}
              >
                Overview
              </Typography>
            </Box>
            <Typography
              variant="body1"
              sx={{
                mt: 1.5,
                color: Color.CHALKBOARD,
                fontSize: 12,
                lineHeight: "18px",
              }}
            >
              {scholarship.description || "No description available"}
            </Typography>
          </Box>
          <Divider sx={{ mt: 1.5, color: Color.SOFT_GRAY }} />
          <Box sx={{ mt: 1.5 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <FeatherIcon icon="gavel" fillColor={Color.DARK_MINT} strokeWidth="0" />
              <Typography variant="h6"
                sx={{
                  color: Color.CHALKBOARD,
                  fontSize: 16,
                  lineHeight: "19.2px",
                }}
              >
                Eligibility Details
              </Typography>
            </Box>
            <Typography sx={{ mt: 1.5, color: Color.CHALKBOARD, fontSize: 12, lineHeight: "18px" }}>
              <strong>Eligibility GPA:</strong> {scholarship.eligibilityGpa || "N/A"}
            </Typography>
            <Typography sx={{ mt: 0.5, color: Color.CHALKBOARD, fontSize: 12, lineHeight: "18px" }}>
              {scholarship.eligibilityDescription || "No eligibility details available"}
            </Typography>
          </Box>
          <Divider sx={{ mt: 1.5, color: Color.SOFT_GRAY }} />
          <Box sx={{ mt: 1.5 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <FeatherIcon icon="calendar" fillColor={Color.DARK_MINT} strokeWidth="0" />
              <Typography variant="h6"
                sx={{
                  color: Color.CHALKBOARD,
                  fontSize: 16,
                  lineHeight: "19.2px",
                }}
              >
                Key Dates
              </Typography>
            </Box>
            <Grid container spacing={0.5} sx={{ mt: 1.5 }}>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ color: Color.CHALKBOARD, fontSize: 12, lineHeight: "18px" }}>
                  <strong>Open Date:</strong>{" "}
                  {scholarship.startAt
                    ? formatDate({ dateString: scholarship.startAt })
                    : "Not Yet Set"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ color: Color.CHALKBOARD, fontSize: 12, lineHeight: "18px" }}>
                  <strong>Deadline:</strong>{" "}
                  {scholarship.endAt
                    ? formatDate({ dateString: scholarship.endAt })
                    : "Not Yet Set"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography sx={{ color: Color.CHALKBOARD, fontSize: 12, lineHeight: "18px" }}>
                <strong>Deadline Description:</strong> {scholarship.deadlineDescription}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ mt: 1.5, color: Color.SOFT_GRAY }} />
          <Box sx={{ mt: 1.5 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <FeatherIcon icon="award-star" fillColor={Color.DARK_MINT} strokeWidth="0" />
              <Typography variant="h6"
                sx={{
                  color: Color.CHALKBOARD,
                  fontSize: 16,
                  lineHeight: "19.2px",
                }}
              >
                Award Information
              </Typography>
            </Box>
            <Typography sx={{ mt: 1.5, color: Color.CHALKBOARD, fontSize: 12, lineHeight: "18px" }}>
              <strong>Award:</strong>{" "}
              {formatAward(scholarship.minAwardAmount, scholarship.maxAwardAmount)}
            </Typography>
            {scholarship.awardDescription && (
              <Typography sx={{ mt: 0.5, color: Color.CHALKBOARD, fontSize: 12, lineHeight: "18px" }}>
                <strong>Award Description:</strong> {scholarship.awardDescription}
              </Typography>
            )}
          </Box>

          {/* Questions */}
          {scholarship.questions.length > 0 && (
            <>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Application Questions
              </Typography>
              {scholarship.questions.map((q, index) => (
                <Box key={index} sx={{ mt: 1 }}>
                  <Typography>
                    <strong>Question {index + 1}:</strong> {q.question || "N/A"}
                  </Typography>
                  {q.questionDetails && (
                    <Typography>
                      <strong>Details:</strong> {q.questionDetails}
                    </Typography>
                  )}
                  <Typography>
                    <strong>Required:</strong> {q.isRequired ? "Yes" : "No"}
                  </Typography>
                </Box>
              ))}
            </>
          )}

          {/* Action Buttons */}
          <Box display="flex" justifyContent="flex-start" alignItems="center" gap={3.5} sx={{ mt: 1.5, py: 2.5 }}>
            <Button
              sx={{
                color: Color.WHITE,
                backgroundColor: Color.CHALKBOARD,
                fontSize: 12,
                fontWeight: 400,
                textTransform: "none",
              }}
              onClick={() =>
                window.open(scholarship.submissionUrl, "_blank")
              }
              variant="contained"
            >
              Apply for the fund
            </Button>
            {(!scholarshipApplication ||
              scholarshipApplication.status === "not applied") && (
              <Button
                sx={{
                  color: Color.BLACK,
                  backgroundColor: Color.SURFACE_GREEN,
                  fontSize: 12,
                  fontWeight: 400,
                  textTransform: "none",
                }}
                onClick={handleUpdateStatus}
                variant="contained"
              >
                Mark As Applied
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ScholarshipDetailsDialog;
