import React from "react";
import { JobDetailsRecord } from "../../types/types";
import StudentJobCard from "../../../students/components/exploreCareers/StudentJobCard";
import { Grid } from "@mui/material";
import { useRecoilValue } from "recoil";
import { userTypeAtom } from "../../recoil/userAtoms";
import { UserType } from "../../types/enums";
import StaffJobCard from "../../../staff/components/exploreCareers/StaffJobCard";

type Props = {
  searchPhrase: string;
  jobs: JobDetailsRecord[];
};

const SearchResults = ({ searchPhrase, jobs }: Props) => {
  const userType = useRecoilValue(userTypeAtom);
  const filteredJobs = jobs.filter((job) =>
    job.title.toLowerCase().includes(searchPhrase.toLowerCase())
  );
  return (
    <>
      <Grid container alignContent="start" spacing="20px">
        {filteredJobs.map((job, index: number) => (
          <Grid key={index} item xs={12} sm={6}>
            {userType === UserType.STUDENT ? (
              <StudentJobCard job={job} />
            ) : (
              <StaffJobCard job={job} />
            )}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default SearchResults;
