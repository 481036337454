import React, { useState, useCallback, useRef, useEffect } from "react";
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/satellite.css";
import {
  Hits,
  InstantSearch,
  Configure,
  connectSearchBox,
} from "react-instantsearch-dom";
import { TextField, Box, Autocomplete } from "@mui/material";
import { SearchedProvider } from "../../../../shared/types/types";

type Props = {
  searchedProviders: SearchedProvider[];
  setSearchedProviders: React.Dispatch<
    React.SetStateAction<SearchedProvider[]>
  >;
  multiple?: boolean;
};

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID
    ? process.env.REACT_APP_ALGOLIA_APP_ID
    : "",
  process.env.REACT_APP_ALGOLIA_APP_KEY
    ? process.env.REACT_APP_ALGOLIA_APP_KEY
    : ""
);

type CustomSearchBoxProps = {
  currentRefinement: string;
  refine: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  value: string;
  onChange: (value: string) => void;
  tags: string[];
  onTagChange: (value: string[]) => void;
  multiple?: boolean;
};

const CustomSearchBox = React.memo(
  ({
    refine,
    onFocus,
    onBlur,
    value,
    onChange,
    tags,
    onTagChange,
    multiple = true,
  }: CustomSearchBoxProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (event: any, newValue: string) => {
      onChange(newValue);
      refine(newValue);
    };

    const handleTagChange = (event: any, newTags: any) => {
      const normalizedValue = multiple 
        ? (newTags as string[])
        : newTags ? [newTags as string] : [];
      onTagChange(normalizedValue);
    };

    return (
      <Autocomplete
        multiple={multiple}
        options={[]}
        freeSolo
        value={multiple ? tags : (tags[0] || '')}
        onChange={handleTagChange}
        inputValue={value}
        onInputChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={inputRef}
            autoComplete="off"
            name="search"
            placeholder="Search for a college or organization by name"
            fullWidth
            onFocus={onFocus}
            onBlur={onBlur}
            sx={{
              "& .MuiInputBase-root": {
                borderRadius: "8px",
              },
            }}
          />
        )}
      />
    );
  }
);

const ConnectedSearchBox = connectSearchBox(CustomSearchBox);

export const SearchFilter = ({
  searchedProviders,
  setSearchedProviders,
  multiple = true,
}: Props) => {
  const [showHits, setShowHits] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const hitClickedRef = useRef(false);

  useEffect(() => {
    if (searchValue.length === 0) {
      setShowHits(false);
    }
  }, [searchValue]);

  const handleSearchFocus = useCallback(() => {
    if (!multiple && searchedProviders.length > 0) {
      return;
    }
    if (searchValue.length > 0) {
      setShowHits(true);
    }
  }, [searchValue, multiple, searchedProviders.length]);

  const handleSearchBlur = useCallback(() => {
    setTimeout(() => {
      if (!hitClickedRef.current) {
        setShowHits(false);
      }
      hitClickedRef.current = false;
    }, 200);
  }, []);

  const handleMultiHitClick = useCallback(
    (hit: any) => {
      hitClickedRef.current = true;
      setSearchValue("");
      setSearchedProviders((prev) => {
        const exists = prev.some(
          (provider) => provider.providerId === hit.objectID
        );

        if (!exists) {
          return [
            ...prev,
            {
              providerId: hit.objectID,
              providerName: hit.providerName,
            },
          ];
        }

        return prev;
      });
      setShowHits(false);
    },
    [setSearchValue, setSearchedProviders]
  );

  const handleSingleHitClick = useCallback(
    (hit: any) => {
      hitClickedRef.current = true;

      setSearchedProviders((prev) => {
        const exists = prev.some(
          (provider) => provider.providerId === hit.objectID
        );

        const newProvider = {
            providerId: hit.objectID,
            providerName: hit.providerName,
          };

        if (!exists) {
          return [newProvider];
        }

        return [];
      });
      setSearchValue("");
      
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    },
    [setSearchedProviders]
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchValue(value);
      if (!multiple && searchedProviders.length > 0) {
        return;
      }
      setShowHits(value.length > 0);
    },
    [multiple, searchedProviders.length]
  );

  const handleSearchTagChange = useCallback(
    (value: string[]) => {
      if (!multiple) {
        setSearchedProviders(value.length === 0 ? [] : searchedProviders);
        return;
      }
      
      const filteredProviders = searchedProviders.filter((provider) =>
        value.includes(provider.providerName)
      );
      setSearchedProviders(filteredProviders);
    },
    [multiple, searchedProviders, setSearchedProviders]
  );
  return (
    <InstantSearch searchClient={searchClient} indexName="providerName">
      <Configure hitsPerPage={10} />
      <Box sx={{ position: "relative", width: "100%" }}>
        <ConnectedSearchBox
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
          value={searchValue}
          onChange={handleSearchChange}
          tags={searchedProviders.map((provider) => provider.providerName)}
          onTagChange={handleSearchTagChange}
          multiple={multiple}
        />

        {showHits && (
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              zIndex: 999,
              backgroundColor: "white",
              boxShadow: 2,
              maxHeight: "500px",
              overflowY: "auto",
            }}
          >
            <Hits
              hitComponent={({ hit }) => (
                <Box
                  sx={{
                    margin: "-20px",
                    width: "100%",
                    height: "100%",
                    padding: 1,
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
                    cursor: "pointer",
                  }}
                  onClick={() => multiple ? handleMultiHitClick(hit) : handleSingleHitClick(hit)}
                >
                  {hit.providerName}
                </Box>
              )}
            />
          </Box>
        )}
      </Box>
    </InstantSearch>
  );
};
