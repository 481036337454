import { useEffect, useState } from "react";
import { generateStartingPrompt } from "../../utils/studentAlmaUtils";
import {
  AlmaChatRecord,
  ChatGPTEntry,
  JobDetailsRecord,
  PersonalityTypeRecord,
  ProviderProgramRecord,
  SchoolRecord,
  StudentRecord,
} from "../../../shared/types/types";
import { useRecoilValue } from "recoil";
import { personalityTypeAtom } from "../../../shared/recoil/personalityTypeAtoms";
import { Collection, FunctionName } from "../../../shared/types/enums";
import { db } from "../../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { parseJobsResponse } from "../../../shared/utils/parserUtils";
import { fetchData } from "../../../shared/utils/fetchUtils";
import { formatProgramName } from "../../../shared/utils/formatUtils";

type Props = {
  loggedInStudent: StudentRecord;
  schools: SchoolRecord[];
  currentStudentAlmaChat: AlmaChatRecord | null;
};

const useChatContainer = ({ loggedInStudent, schools, currentStudentAlmaChat }: Props) => {
  const [chatHistory, setChatHistory] = useState<ChatGPTEntry[]>([]);
  const personalityType = useRecoilValue(personalityTypeAtom);
  const [initialChatContext, setInitialChatContext] = useState<string>("");

  useEffect(() => {
    const loadInitialContext = async () => {
      if (currentStudentAlmaChat) {
        setChatHistory(currentStudentAlmaChat.chatHistory);
      } else {
        setChatHistory([]);
        const startingPrompt = await generateInitialPrompt(
          loggedInStudent,
          schools,
          personalityType
        );
        setInitialChatContext(startingPrompt);
      }
    };
    loadInitialContext();
  }, [currentStudentAlmaChat, loggedInStudent, personalityType, schools]);

  return { chatHistory, setChatHistory, initialChatContext };
};

export default useChatContainer;

// Helper function to generate initial prompt
const generateInitialPrompt = async (
  loggedInStudent: StudentRecord,
  schools: SchoolRecord[],
  personalityType: PersonalityTypeRecord | null
): Promise<string> => {
  const bookmarkedCareerIds = await getBookmarkedCareerIds(loggedInStudent);
  const recommendedCareerIds = [
    ...loggedInStudent.staffRecommendedJobIds,
    ...loggedInStudent.willowRecommendedJobIds,
  ];

  const bookmarkedJobsData = await getJobsInBatches(bookmarkedCareerIds);
  const recommendedJobsData = await getJobsInBatches(recommendedCareerIds);

  const bookmarkedProgramNames = await getProgramNames(loggedInStudent.favoriteProgramIds);
  const recommendedProgramNames = await getProgramNames([
    ...loggedInStudent.staffRecommendedProgramIds,
    ...loggedInStudent.willowRecommendedProgramIds,
  ]);

  const bookmarkedJobNames = bookmarkedJobsData.map((job) => job.title);
  const recommendedJobNames = recommendedJobsData.map((job) => job.title);

  return generateStartingPrompt({
    loggedInStudent,
    schools,
    personalityType,
    bookmarkedJobNames,
    recommendedJobNames,
    bookmarkedProgramNames,
    recommendedProgramNames,
  });
};

// Fetch bookmarked career IDs from video interactions
const getBookmarkedCareerIds = async (loggedInStudent: StudentRecord): Promise<string[]> => {
  const videoInteractionsQuery = query(
    collection(db, Collection.VIDEO_INTERACTIONS),
    where("studentId", "==", loggedInStudent.id),
    where("interactionType", "==", "liked")
  );

  const snapshot = await getDocs(videoInteractionsQuery);
  const videoInteractions = snapshot.docs.map((doc) => doc.data());
  return videoInteractions.map((interaction) => interaction.onetCode);
};

// Fetch program names by their IDs
const getProgramNames = async (programIds: string[]): Promise<string[]> => {
  const response = await fetchData({
    functionName: FunctionName.GET_PROGRAMS_AND_PROVIDERS_BY_ID,
    payload: { documentIds: programIds },
  });
  const { programs } = await response.json();
  return programs.map(
    (program: ProviderProgramRecord) => `${formatProgramName({ name: program.programName })} at ${program.providerName}`
  );
};

// Helper function to fetch jobs in batches
const getJobsInBatches = async (careerIds: string[]): Promise<JobDetailsRecord[]> => {
  const jobsRef = collection(db, "jobs");
  const batchSize = 10;
  let jobData: JobDetailsRecord[] = [];

  for (let i = 0; i < careerIds.length; i += batchSize) {
    const batchIds = careerIds.slice(i, i + batchSize);
    const q = query(jobsRef, where("__name__", "in", batchIds));

    const querySnapshot = await getDocs(q);
    const batchData = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as JobDetailsRecord[];

    jobData = jobData.concat(batchData);
  }

  return parseJobsResponse(jobData);
};
