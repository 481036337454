import { FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  Collection,
  FunctionName,
  GradeLevel,
  KlaviyoListType,
  PageRoute,
  UserType,
} from "../../../shared/types/enums";
import useSetFSDoc from "../../../shared/hooks/db/useSetFSDoc";
import { User } from "firebase/auth";
import { useCallback } from "react";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { LogEventType } from "../../../shared/types/logEnums";
import { useSetRecoilState } from "recoil";
import { loggedInStudentAtom, userTypeAtom } from "../../../shared/recoil/userAtoms";
import { parseSingleStudentResponse } from "../../../shared/utils/parserUtils";
import { SchoolRecord, StudentRecord } from "../../../shared/types/types";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../shared/utils/fetchUtils";

type Props = {
  currentAuthUser: User;
  schools: SchoolRecord[];
};

const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .length(16, "Please make sure to input a full phone number"),
  schoolId: Yup.string().required("Please select your school"),
  gradeLevel: Yup.string().required("Please select your grade level"),
  externalId: Yup.string().notRequired(),
});

type FormType = {
  firstName: string;
  lastName: string;
  schoolId: string;
  externalId: string;
  phone: string;
  gradeLevel: string;
};

const initialValues = {
  firstName: "",
  lastName: "",
  schoolId: "",
  externalId: "",
  phone: "",
  gradeLevel: "",
};

function useCreateProfileForm({ currentAuthUser, schools }: Props) {
  const { submitLog } = useLogger();
  const { setFSDoc } = useSetFSDoc();
  const gradeLevels: GradeLevel[] = Object.values(GradeLevel).map((value) => value);
  const setUserType = useSetRecoilState(userTypeAtom);
  const setLoggedInStudent = useSetRecoilState(loggedInStudentAtom);
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (values: FormType, { setSubmitting }: FormikHelpers<FormType>) => {
      const selectedSchool = schools.find((school) => school.id === values.schoolId);

      if (!currentAuthUser) return;
      try {
        const klaviyoResults = await fetchData({
          functionName: FunctionName.CREATE_PROFILE_AND_ADD_TO_LIST_KLAVIYO,
          payload: {
            email: currentAuthUser.email,
            firstName: values.firstName,
            lastName: values.lastName,
            listType: KlaviyoListType.STUDENT,
          },
        });
        const klaviyoResultsJson = await klaviyoResults.json();
        const klaviyoProfileId = klaviyoResultsJson.id ?? null;

        const newUser = await setFSDoc({
          col: Collection.STUDENTS,
          data: {
            ...values,
            email: currentAuthUser.email,
            setupComplete: false,
            schoolId: selectedSchool?.id ?? null,
            externalId: values.externalId !== "" ? values.externalId : null,
            districtId: selectedSchool?.districtId ?? null,
            klaviyoProfileId: klaviyoProfileId,
          },
          id: currentAuthUser.uid,
        });
        const parsedNewUser = parseSingleStudentResponse(newUser as StudentRecord);
        setLoggedInStudent(parsedNewUser);
        setUserType(UserType.STUDENT);
        submitLog({
          eventType: LogEventType.USER_CREATED_PROFILE,
          file: "CreateProfileForm.ts",
        });
      } catch (error) {
        submitLog({
          error,
          snackbarMessage:
            "There was an error creating your profile, please refresh and try again.",
          eventType: LogEventType.USER_CREATE_PROFILE_ERROR,
          file: "CreateProfileForm.ts",
        });
      }
      setSubmitting(false);
      navigate(PageRoute.STUDENT_PROFILE);
    },
    [currentAuthUser, navigate, schools, setFSDoc, setLoggedInStudent, setUserType, submitLog]
  );

  return { initialValues, validationSchema, gradeLevels, handleSubmit };
}

export default useCreateProfileForm;
