import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import JobDetailsContainer from "../jobDetails/JobDetailsContainer";
import { useRecoilState } from "recoil";
import { selectedJobIdAtom } from "../../../shared/recoil/exploreCareersAtoms";

const JobDetailsDialog = () => {
  const [selectedJobId, setSelectedJobId] = useRecoilState(selectedJobIdAtom);
  const { width } = useWindowDimensions();
  const mobile = width < 900;
  return (
    <Dialog
      open={Boolean(selectedJobId)}
      onClose={() => setSelectedJobId(null)}
      maxWidth={"xl"}
      fullWidth={!mobile}
      fullScreen={mobile}
    >
      <DialogContent sx={{ height: "80VH" }}>
        <JobDetailsContainer />
      </DialogContent>
    </Dialog>
  );
};

export default JobDetailsDialog;
