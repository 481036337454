import { Box, Card, CardContent, Typography } from "@mui/material";
import { Color, QuizResultsOption } from "../../types/enums";
import { PersonalityTypeRecord } from "../../types/types";
import NavigationButtons from "./NavigationButtons";

type Props = {
  mobile: boolean;
  personalityType: PersonalityTypeRecord;
  onActionButtonClick: (value: QuizResultsOption) => void;
};

const RelationshipTips = ({
  mobile,
  personalityType,
  onActionButtonClick,
}: Props) => {
  const getTextExcludingLastSentence = (text: string): string => {
    const sentences = text.match(/[^.!?]+[.!?]/g);
    if (sentences && sentences.length > 1) {
      return sentences.slice(0, -1).join(" ").trim();
    }
    return "";
  };

  const getLastSentence = (text: string): string => {
    const sentences = text.match(/[^.!?]+[.!?]/g);
    return sentences ? sentences[sentences.length - 1].trim() : text;
  };

  return (
    <Box>
      <Typography
        sx={{ mt: 2, fontWeight: 600 }}
        variant="h6"
        fontSize={16}
        color={Color.ORANGE_700}
      >
        {personalityType.title}
      </Typography>
      <Typography variant="h3" fontSize={30} color={Color.ORANGE_700}>
        {QuizResultsOption.RELATIONSHIP_TIPS}
      </Typography>
      <Typography
        sx={{ mt: 4 }}
        variant="body1"
        fontSize={14}
        color={Color.CHALKBOARD}
      >
        {getTextExcludingLastSentence(personalityType.relationshipTips)}
      </Typography>
      <Card
        sx={{
          mt: 4,
          borderLeft: 3,
          borderColor: Color.BLACK,
          borderRadius: 0,
          boxShadow: "none",
        }}
      >
        <CardContent>
          <Typography
            sx={{ mt: 1 }}
            variant="body1"
            fontSize={14}
            color={Color.CHALKBOARD}
          >
            {getLastSentence(personalityType.relationshipTips)}
          </Typography>
        </CardContent>
      </Card>
      <NavigationButtons
        mobile={mobile}
        currentTitle={QuizResultsOption.RELATIONSHIP_TIPS}
        onNextClick={(nextTitle) => onActionButtonClick(nextTitle)}
        onPreviousClick={(nextTitle) => onActionButtonClick(nextTitle)}
      />
    </Box>
  );
};

export default RelationshipTips;
