import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import useWindowDimensions from "../../hooks/responsiveLayout/useWindowDimensions";
import ResetQuizResultsDialog from "./ResetQuizResultsDialog";
import QuizResultsDesktop from "./QuizResultsDesktop";
import { Color, UserType } from "../../types/enums";
import QuizResultsMobile from "./QuizResultsMobile";
import useQuizResultsDialog from "../../hooks/quizResults/useQuizResultsDialog";
import CloseIcon from "../closeIcon/CloseIcon";

type Props = {
  open: boolean;
  userType: UserType;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setUnlockMatchesOpen?: (open: boolean) => void;
};

const QuizResultsDialog = ({ open, userType, setOpen, setUnlockMatchesOpen }: Props) => {
  const { dialogContentRef, student, handleClose } = useQuizResultsDialog({
    userType,
    setOpen,
    setUnlockMatchesOpen,
  });
  const { width } = useWindowDimensions();
  const mobile = width < 900;
  const [resetQuizOpen, setResetQuizOpen] = useState(false);

  return (
    <>
      {student && (
        <>
          <Dialog
            open={open}
            fullScreen={mobile}
            fullWidth={!mobile}
            maxWidth="xl"
            onClose={handleClose}
          >
            <DialogTitle
              sx={{
                backgroundColor: Color.GRAY_50,
                fontSize: mobile ? 18 : 30,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>Your Personality Type</span>
              <CloseIcon onClose={handleClose} />
            </DialogTitle>
            <DialogContent sx={{ px: 0, backgroundColor: Color.GRAY_50 }} ref={dialogContentRef}>
              {!mobile && <QuizResultsDesktop dialogContentRef={dialogContentRef} />}
              {mobile && <QuizResultsMobile dialogContentRef={dialogContentRef} />}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "flex-start" }}>
              {userType === UserType.STUDENT && (
                <Button color="close" variant="outlined" onClick={() => setResetQuizOpen(true)}>
                  Reset Quiz
                </Button>
              )}
            </DialogActions>
          </Dialog>

          <ResetQuizResultsDialog
            open={resetQuizOpen}
            setOpen={setResetQuizOpen}
            userType={userType}
          />
        </>
      )}
    </>
  );
};

export default QuizResultsDialog;
