import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FunctionName } from "../../types/enums";
import { ProviderProgramRecord, ProviderRecord } from "../../types/types";
import { fetchData } from "../../utils/fetchUtils";

type Props = {
  providerId: string;
};

const useProviderDetailsDialog = ({ providerId }: Props) => {
  const navigate = useNavigate();
  const [currentProvider, setCurrentProvider] = useState<ProviderRecord | null>(null);
  const [programsByProvider, setProgramsByProvider] = useState<ProviderProgramRecord[]>([]);
  const [getProviderTrigger, setGetProviderTrigger] = useState(false);

  useEffect(() => {
    const getProvider = async () => {
      const results = await fetchData({
        functionName: FunctionName.GET_PROVIDER_BY_ID,
        payload: { providerId: providerId },
      });
      const { provider } = (await results.json()) as {
        provider: ProviderRecord;
      };
      setCurrentProvider(provider ? provider : null);
    };

    const getProgramsByProviderId = async () => {
      const results = await fetchData({
        functionName: FunctionName.GET_PROGRAMS_AND_PROVIDER_BY_PROVIDER_ID,
        payload: { providerId: providerId },
      });
      const matchedResults = await results.json();
      setProgramsByProvider(matchedResults.programs);
    };

    getProvider();
    getProgramsByProviderId();
  }, [providerId, getProviderTrigger]);

  const navigateBack = () => {
    navigate(-1);
  };

  return {
    currentProvider,
    programsByProvider,
    navigateBack,
    setGetProviderTrigger,
  };
};

export default useProviderDetailsDialog;