import { FunctionName } from "../../shared/types/enums";
import {
  ChatGPTEntry,
  PersonalityTypeRecord,
  SchoolRecord,
  StudentRecord,
} from "../../shared/types/types";
import { fetchData } from "../../shared/utils/fetchUtils";

type GenerateAlmaProps = {
  loggedInStudent: StudentRecord;
  schools: SchoolRecord[];
  personalityType: PersonalityTypeRecord | null;
  bookmarkedJobNames: string[];
  recommendedJobNames: string[];
  bookmarkedProgramNames: string[];
  recommendedProgramNames: string[];
};

const buildOpening = (loggedInStudent: StudentRecord) => {
  return `
**Your Role:**
You are Alma, an AI post-secondary counselor for a high school student named ${loggedInStudent.firstName}. Your role is to help ${loggedInStudent.firstName} find high-quality career paths and post-secondary programs that align with their interests and strengths.

**Important:**
- You MUST use ${loggedInStudent.firstName}'s personal details in every response.
- Reference ${loggedInStudent.firstName}'s academic and personal background to provide personalized guidance.
- Tailor your responses to ${loggedInStudent.firstName}'s interests and goals.
- Keep your answers clear, concise (max one paragraph), and actionable.
- Always reference something that you know about a student when starting a conversation with them.
- End every response with ONE clear, open-ended question to guide the conversation forward.
`;
};

const buildContext = (
  loggedInStudent: StudentRecord,
  personalityType: PersonalityTypeRecord | null,
  school: SchoolRecord | undefined,
  bookmarkedJobNames: string[],
  recommendedJobNames: string[],
  bookmarkedProgramNames: string[],
  recommendedProgramNames: string[]
) => {
  let context = `
**Student Information:**
- Name: ${loggedInStudent.firstName}
- Grade: ${loggedInStudent.gradeLevel} at ${school?.name || "an unknown school"}
- GPA: ${loggedInStudent.gpaValue} out of ${loggedInStudent.gpaMax}
- Goal: "${loggedInStudent.myWhy}"
- Described themselves as: "${loggedInStudent.whatDescribesMe}"
- Location: ${loggedInStudent?.address?.city ?? "Unknown City"}, ${
    loggedInStudent?.address?.state ?? "Unknown State"
  }

**Career and Program Interests:**
- Interested Careers: ${bookmarkedJobNames.join(", ") || "None"}
- Suggested Careers: ${recommendedJobNames.join(", ")}
- Interested Colleges, Majors, and or Professional Programs: ${
    bookmarkedProgramNames.join(", ") || "None"
  }
- Suggested  Colleges, Majors, and or Professional Programs: ${recommendedProgramNames.join(", ")}
- Preferred Location: ${
    loggedInStudent.citiesForRecommendations.join(", ") || "anywhere in the U.S."
  }

**Personality Type:**
- Personality: ${personalityType?.title || "Unknown"}
- Works best in: ${personalityType?.workStyle || "Unknown work style"}

`;
  return context;
};

const buildExamples = (loggedInStudent: StudentRecord) => {
  return `
**Response Guidelines:**

1. Always mention ${loggedInStudent.firstName}'s background, strengths, and goals in every message.
2. Focus on one topic per message, and finish with one open-ended question.
3. Make your responses clear, concise, actionalbe, and use bullet points when possible. 
4. Avoid generic responses; tailor everything to ${loggedInStudent.firstName}.

**Example Warm-Demanding Responses:**
- **Warm:** "${loggedInStudent.firstName}, your interest in Management Analyst and Statistician roles shows a clear strength in analytical thinking. Let's explore programs that match that strength."
- **Demanding:** "It’s important to focus on programs with strong completion rates. Would you like me to suggest options with high success rates and good job placement?"

Make sure you provide actionable, specific recommendations that guide ${loggedInStudent.firstName} toward well-informed decisions.
`;
};

const generateAlmaContextPrompt = ({
  loggedInStudent,
  schools,
  personalityType,
  bookmarkedJobNames,
  recommendedJobNames,
  bookmarkedProgramNames,
  recommendedProgramNames,
}: GenerateAlmaProps) => {
  const school = schools.find((school) => school.id === loggedInStudent.schoolId);
  let fullPrompt = buildOpening(loggedInStudent);
  fullPrompt += buildContext(
    loggedInStudent,
    personalityType,
    school,
    bookmarkedJobNames,
    recommendedJobNames,
    bookmarkedProgramNames,
    recommendedProgramNames
  );
  fullPrompt += buildExamples(loggedInStudent);
  return fullPrompt;
};

type StartingPromptProps = {
  loggedInStudent: StudentRecord;
  schools: SchoolRecord[];
  personalityType: PersonalityTypeRecord | null;
  bookmarkedJobNames: string[];
  recommendedJobNames: string[];
  bookmarkedProgramNames: string[];
  recommendedProgramNames: string[];
};

export const generateStartingPrompt = ({
  loggedInStudent,
  schools,
  personalityType,
  bookmarkedJobNames,
  recommendedJobNames,
  bookmarkedProgramNames,
  recommendedProgramNames,
}: StartingPromptProps) => {
  const initialContext = generateAlmaContextPrompt({
    loggedInStudent,
    schools,
    personalityType,
    bookmarkedJobNames,
    recommendedJobNames,
    bookmarkedProgramNames,
    recommendedProgramNames,
  });

  return initialContext;
};

//helper that turns a message into a new chat entrys
export const buildChatEntry = (newMessage: string) => {
  const entry: ChatGPTEntry = {
    role: "user",
    content: newMessage,
    timestamp: new Date().toISOString(),
  };
  return entry;
};

type getAlmaResponseProps = {
  chatHistoryForAI: ChatGPTEntry[];
  newEntry: ChatGPTEntry;
  contextToAdd: string;
  initialContext: string;
  loggedInStudent: StudentRecord;
};

//helper that gets alma's reply and returns it as a chat entry type
export const getAlmaResponse = async ({
  chatHistoryForAI,
  newEntry,
  contextToAdd,
  initialContext,
  loggedInStudent,
}: getAlmaResponseProps) => {
  const response = await fetchData({
    functionName: FunctionName.CHAT_GPT_ALMA,
    payload: {
      chatHistory: chatHistoryForAI,
      message: newEntry.content,
      contextToAdd,
      initialContext,
      loggedInStudent: loggedInStudent,
    },
  });
  const textResponse = await response.json();
  const text = response
    ? await textResponse.response
    : "I'm sorry, I'm not able to respond to that right now. Please try again later.";
  const responseToSave: ChatGPTEntry = {
    role: "assistant",
    timestamp: new Date().toISOString(),
    content: text,
  };
  return responseToSave;
};
