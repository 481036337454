import { ProviderProgramRecord } from "../../../shared/types/types";
import { Box, IconButton, Typography } from "@mui/material";
import { Color } from "../../../shared/types/enums";
import { ChevronRightOutlined } from "@mui/icons-material";
import { formatProgramName } from "../../../shared/utils/formatUtils";

type Props = {
  providerProgram: ProviderProgramRecord;
  setSelectedProgramDetail: (program: ProviderProgramRecord | null) => void;
};

const StaffProgramTitleProviderCard = ({ providerProgram, setSelectedProgramDetail }: Props) => {
  return (
    <>
      <Box
        sx={{
          borderBottom: "1px solid #eaeaea",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 1.5 }}
        >
          <Box>
            <Typography
              textAlign={"left"}
              variant="h6"
              sx={{ color: Color.CHALKBOARD, fontSize: 14 }}
            >
              {formatProgramName({ name: providerProgram.programName })}
            </Typography>
            <Typography
              textAlign={"left"}
              variant="body1"
              sx={{ color: Color.CHALKBOARD, fontSize: 12 }}
            >
              {providerProgram.providerName}
            </Typography>
          </Box>
          <IconButton onClick={() => setSelectedProgramDetail(providerProgram)}>
            <ChevronRightOutlined />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default StaffProgramTitleProviderCard;
