import Industries from "./Industries";
import Fields from "./Fields";
import Jobs from "./Jobs";
import Breadcrumb from "./Breadcrumb";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import useExploreCareeresRouter from "../../hooks/exploreCareers/useExploreCareeresRouter";
import useWindowDimensions from "../../hooks/responsiveLayout/useWindowDimensions";
import { useRecoilValue } from "recoil";

import { selectedFieldIdAtom, selectedIndustryIdAtom } from "../../recoil/exploreCareersAtoms";
import { useCallback, useState } from "react";
import SearchResults from "./SearchResults";

const ExploreCareersRouter = () => {
  const { jobs } = useExploreCareeresRouter();
  const { width } = useWindowDimensions();
  const mobile = width < 900;
  const selectedIndustryId = useRecoilValue(selectedIndustryIdAtom);
  const selectedFieldId = useRecoilValue(selectedFieldIdAtom);
  const [searchPhrase, setSearchPhrase] = useState("");

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(event.target.value);
  }, []);

  return (
    <Box
      sx={{ height: mobile ? "calc(100vh - 180px)" : "calc(100vh - 110px)", overflowY: "scroll" }}
    >
      <Box sx={{ px: mobile ? 2.5 : 3, py: 1 }}>
        <Typography variant="h5">Search</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9} lg={10}>
            <TextField fullWidth onChange={handleSearch} value={searchPhrase} />
          </Grid>
          <Grid item xs={12} sm={3} lg={2}>
            <Button
              fullWidth
              variant="contained"
              sx={{
                height: "100%",
                textTransform: "none",
                fontSize: 12,
                fontWeight: 400,
                paddingY: 1,
                paddingX: 1.5,
              }}
              onClick={() => setSearchPhrase("")}
            >
              Reset Search
            </Button>
          </Grid>
        </Grid>
      </Box>
      {searchPhrase.length > 3 ? (
        <Box sx={{ px: mobile ? 2.5 : 3, py: 1 }}>
          <Typography variant="h5">Search Results</Typography>
          <SearchResults searchPhrase={searchPhrase} jobs={jobs} />
        </Box>
      ) : (
        <Box sx={{ px: mobile ? 2.5 : 3, py: 1 }}>
          {!selectedIndustryId ? (
            <Box>
              <Industries />
            </Box>
          ) : (
            <>
              {!selectedFieldId ? (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2.5 }}>
                  <Breadcrumb jobs={jobs} />
                  <Fields />
                </Box>
              ) : (
                <>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2.5 }}>
                    <Breadcrumb jobs={jobs} />
                    <Jobs jobs={jobs} />
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ExploreCareersRouter;
