import { Box } from "@mui/material";
import UploadNonCollegeDataContainer from "../components/UploadNonCollegeData/UploadNonCollegeDataContainer";

const UploadNonCollegeDataPage = () => {
  return (
    <Box sx={{ minHeight: "calc(100VH-64px)", p:2 }}>
      <UploadNonCollegeDataContainer />
    </Box>
  );
};

export default UploadNonCollegeDataPage;
