import FavoriteIcon from "@mui/icons-material/Favorite";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { ProviderProgramRecord, StudentRecord } from "../../../shared/types/types";
import useProviderList from "../../hooks/programList/useProviderList";
import { formatProgramName } from "../../../shared/utils/formatUtils";

type Props = {
  providerId: string;
  favoriteOnlyToggle: boolean;
  fromAllSchoolsToggle: boolean;
  matchedPrograms: ProviderProgramRecord[];
  allEnrolledDistrictStudents: StudentRecord[];
};

function ProviderList({
  providerId,
  favoriteOnlyToggle,
  fromAllSchoolsToggle,
  matchedPrograms,
  allEnrolledDistrictStudents,
}: Props) {
  const { currentProvider, studentsList } = useProviderList({
    providerId,
    favoriteOnlyToggle,
    fromAllSchoolsToggle,
    matchedPrograms,
    allEnrolledDistrictStudents,
  });

  return (
    <>
      {currentProvider && studentsList.length > 0 && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Box>
            <Typography variant="h3" sx={{ mb: 2 }}>
              {currentProvider.providerName}
            </Typography>
            <Grid container spacing={2}>
              {studentsList.map((student) => (
                <Grid key={`${student.id}_${currentProvider.programName}`} item xs={12} sm={6}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      {student.firstName} {student.lastName}:
                    </Typography>
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      {formatProgramName({ name: student.programName })}
                    </Typography>
                    {student.favorited === "Yes" ? (
                      <FavoriteIcon color="secondary" sx={{ fontSize: 12, ml: 1 }} />
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Divider sx={{ mt: 1 }} />
        </Paper>
      )}
    </>
  );
}

export default ProviderList;
