import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ProviderProgramRecord, StudentRecord } from "../../../shared/types/types";
import { allSchoolStudentsAtom } from "../../recoil/studentsAtoms";
import { convertToCSV } from "../../utils/csvUtils";
import { formatProgramName } from "../../../shared/utils/formatUtils";

type StudentsForExport = {
  firstName: string;
  lastName: string;
  schoolEmail: string;
  providerName: string;
  programName: string;
  favorited: "Yes" | "No";
};

type Props = {
  programId: string;
  favoriteOnlyToggle: boolean;
  fromAllSchoolsToggle: boolean;
  matchedPrograms: ProviderProgramRecord[];
  allEnrolledDistrictStudents: StudentRecord[];
};

const useProgramList = ({
  programId,
  favoriteOnlyToggle,
  fromAllSchoolsToggle,
  matchedPrograms,
  allEnrolledDistrictStudents,
}: Props) => {
  const [studentsList, setStudentList] = useState<StudentRecord[]>([]);
  const [studentsForExport, setStudentsForExport] = useState("");
  const allEnrolledGroupStudents = useRecoilValue(allSchoolStudentsAtom);
  const currentProvider = matchedPrograms ? matchedPrograms.find((p) => p.id === programId) : null;

  const cleanedProgramName = currentProvider ? formatProgramName({ name: currentProvider.programName }) : "No Program Name";

  useEffect(() => {
    const filteredStudents = (
      fromAllSchoolsToggle ? allEnrolledDistrictStudents : allEnrolledGroupStudents
    ).filter((s) => s.willowRecommendedProgramIds.includes(programId));
    const filteredFavoritedStudents = filteredStudents.filter((s) =>
      s.favoriteProgramIds.includes(programId)
    );
    setStudentList(favoriteOnlyToggle ? filteredFavoritedStudents : filteredStudents);
    const tempArrayForExport: StudentsForExport[] = [];
    if (favoriteOnlyToggle) {
      filteredFavoritedStudents.forEach((student) => {
        tempArrayForExport.push({
          firstName: student.firstName,
          lastName: student.lastName,
          schoolEmail: student.schoolEmail,
          providerName: currentProvider?.providerName ?? "",
          programName: cleanedProgramName,
          favorited: student.favoriteProgramIds.includes(programId) === true ? "Yes" : "No",
        });
      });
    } else {
      filteredStudents.forEach((student) => {
        tempArrayForExport.push({
          firstName: student.firstName,
          lastName: student.lastName,
          schoolEmail: student.schoolEmail,
          providerName: currentProvider?.providerName ?? "",
          programName: cleanedProgramName,
          favorited: student.favoriteProgramIds.includes(programId) === true ? "Yes" : "No",
        });
      });
    }
    const arrayForExport = convertToCSV(tempArrayForExport);
    setStudentsForExport(arrayForExport);
  }, [
    fromAllSchoolsToggle,
    allEnrolledGroupStudents,
    allEnrolledDistrictStudents,
    currentProvider,
    favoriteOnlyToggle,
    programId,
    cleanedProgramName,
  ]);
  return { currentProvider, studentsList, studentsForExport, cleanedProgramName };
};

export default useProgramList;
