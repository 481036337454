import { useState, useCallback, Dispatch, SetStateAction } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import {
  AlmaChat,
  AlmaChatRecord,
  BaseRecord,
  ChatGPTEntry,
  StudentRecord,
} from "../../../shared/types/types";
import { currentStudentAlmaChatAtom, studentAlmaChatsAtom } from "../../../shared/recoil/almaAtoms";
import { Collection } from "../../../shared/types/enums";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import useAddFSDoc from "../../../shared/hooks/db/useAddFSDoc";
import { LogEventType } from "../../../shared/types/logEnums";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { buildChatEntry, getAlmaResponse } from "../../utils/studentAlmaUtils";

type Props = {
  chatHistory: ChatGPTEntry[];
  setChatHistory: Dispatch<SetStateAction<ChatGPTEntry[]>>;
  initialChatContext: string;
};

const useSubmitChat = ({ chatHistory, setChatHistory, initialChatContext }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const currentStudentAlmaChat = useRecoilValue(currentStudentAlmaChatAtom);
  const setStudentAlmaChats = useSetRecoilState(studentAlmaChatsAtom);
  const { updateFSDoc } = useUpdateFSDoc();
  const { addFSDoc } = useAddFSDoc();
  const { submitLog } = useLogger();

  const logChatSubmission = useCallback(() => {
    submitLog({
      eventType: LogEventType.SUBMITTED_STUDENT_ALMA_CHAT,
      file: "useChatContainer.ts",
    });
  }, [submitLog]);

  const handleChatError = useCallback(
    (error: any) => {
      submitLog({
        error,
        snackbarMessage:
          "Alma had a hard time responding to that chat, please refresh and try again",
        eventType: LogEventType.SUBMITTED_STUDENT_ALMA_CHAT_ERROR,
        file: "useChatContainer.ts",
      });
    },
    [submitLog]
  );

  const addNewChatRecord = useCallback(
    async ({
      newEntry,
      responseToSave,
      loggedInStudent,
      adminEntry,
    }: {
      chatHistory: ChatGPTEntry[];
      newEntry: ChatGPTEntry;
      responseToSave: ChatGPTEntry;
      loggedInStudent: StudentRecord;
      adminEntry: ChatGPTEntry;
    }) => {
      const now = new Date().toDateString();
      const data: AlmaChat = {
        chatHistory: [adminEntry, newEntry, responseToSave],
        studentId: loggedInStudent.id,
        current: true,
        schoolId: loggedInStudent?.schoolId ?? "",
        districtId: loggedInStudent?.districtId ?? "",
        name: now,
      };
      const meta: BaseRecord = await addFSDoc({
        col: Collection.ALMA_CHATS,
        data: data,
      });
      return { ...data, ...meta };
    },
    [addFSDoc]
  );

  const updateNewChatRecord = useCallback(
    async ({
      currentStudentAlmaChat,
      newEntry,
      responseToSave,
    }: {
      currentStudentAlmaChat: AlmaChatRecord;
      newEntry: ChatGPTEntry;
      responseToSave: ChatGPTEntry;
    }) => {
      const data = {
        chatHistory: [...currentStudentAlmaChat.chatHistory, newEntry, responseToSave],
      };

      await updateFSDoc({
        col: Collection.ALMA_CHATS,
        id: currentStudentAlmaChat.id,
        data: data,
      });
      return data;
    },
    [updateFSDoc]
  );

  const updateChatRecord = useCallback(
    async ({
      newEntry,
      responseToSave,
      loggedInStudent,
      adminEntry,
    }: {
      newEntry: ChatGPTEntry;
      responseToSave: ChatGPTEntry;
      loggedInStudent: StudentRecord;
      adminEntry: ChatGPTEntry;
    }) => {
      if (!currentStudentAlmaChat) {
        const record = await addNewChatRecord({
          chatHistory,
          newEntry,
          responseToSave,
          loggedInStudent,
          adminEntry,
        });
        if (record) setStudentAlmaChats([record]);
      } else {
        const data = await updateNewChatRecord({
          currentStudentAlmaChat,
          newEntry,
          responseToSave,
        });
        setStudentAlmaChats((prev) =>
          prev.map((chat) =>
            chat.id === currentStudentAlmaChat.id
              ? { ...chat, chatHistory: data.chatHistory }
              : chat
          )
        );
      }
    },
    [
      addNewChatRecord,
      chatHistory,
      currentStudentAlmaChat,
      setStudentAlmaChats,
      updateNewChatRecord,
    ]
  );

  const handleSubmit = useCallback(
    async (newMessage: string) => {
      if (!loggedInStudent) return;
      const newEntry = buildChatEntry(newMessage);
      setIsLoading(true);
      const originalChatHistory = [...chatHistory];
      const adminEntry: ChatGPTEntry = {
        role: "system",
        content: initialChatContext,
        timestamp: new Date().toISOString(),
      };
      if (chatHistory.length === 0) {
        setChatHistory([adminEntry, newEntry]);
      } else {
        setChatHistory((prev) => [...prev, newEntry]);
      }

      try {
        const responseToSave = await getAlmaResponse({
          chatHistoryForAI: originalChatHistory,
          newEntry,
          contextToAdd: "",
          initialContext: initialChatContext,
          loggedInStudent: loggedInStudent,
        });

        setChatHistory((prev) => [...prev, responseToSave]);

        await updateChatRecord({ newEntry, responseToSave, loggedInStudent, adminEntry });
        logChatSubmission();

        setMessage("");
      } catch (err) {
        handleChatError(err);
      } finally {
        setIsLoading(false);
        setMessage("");
      }
    },
    [
      chatHistory,
      handleChatError,
      logChatSubmission,
      loggedInStudent,
      setChatHistory,
      updateChatRecord,
      initialChatContext,
    ]
  );

  return { handleSubmit, isLoading, message, setMessage };
};

export default useSubmitChat;
