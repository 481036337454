import { useEffect, useState } from "react";
import { Box, Toolbar, Typography, Avatar, IconButton } from "@mui/material";
import { useRecoilValue } from "recoil";
import { loggedInStaffAtom } from "../../../shared/recoil/userAtoms";
import { PageRoute } from "../../../shared/types/enums";
import TopBar from "../../../shared/components/navigation/TopBar";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import useLogout from "../../../shared/hooks/auth/useLogout";
import { TourSelector } from "../../../shared/types/tourEnums";
import { useNavigate } from "react-router-dom";
import { getImageUrl } from "../../../shared/utils/imageUtils";
import StaffSchoolSelect from "./StaffSchoolSelect";
import StaffStudentToggle from "../../../shared/components/navigation/StaffStudentToggle";
import willowLogo from "../../../shared/assets/branding/inline-chalkboard.svg";

const StaffTopDesktopNavbar = () => {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const { logout } = useLogout();
  const navigate = useNavigate();
  const [avatarURL, setAvatarURL] = useState<string | null>(null);
  useEffect(() => {
    if (!loggedInStaff) return;
    const getURL = async () => {
      const url = await getImageUrl(loggedInStaff.avatar);
      setAvatarURL(url);
    };
    getURL();
  }, [loggedInStaff]);

  return (
    <>
      {loggedInStaff && (
        <TopBar position="sticky" sx={{ width: "100%", ml: "1px", zIndex: 10 }}>
          <Toolbar sx={{ backgroundColor: "#fff" }}>
            <Box sx={{ flexGrow: 1, pt: "2px", pb: "2px" }}>
              <Box
                onClick={() => navigate(PageRoute.FEED)}
                sx={{
                  display: "flex",
                  alignContent: "flex-start",
                  alignItems: "center",
                  gap: 2,
                  padding: 1,
                  cursor: "pointer",
                }}
              >
                <img style={{ width: "200px", height: "34px" }} src={willowLogo} alt="Willow" />
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ color: "#333" }}>{loggedInStaff?.email ?? ""}</Typography>
              <Avatar
                alt={loggedInStaff.avatar}
                src={avatarURL ? avatarURL : ""}
                sx={{ ml: 2, backgroundColor: "#F4F0DC", color: "#00362E", cursor: "pointer" }}
                data-tut={TourSelector.PROFILE}
                onClick={() => navigate(PageRoute.STUDENT_PROFILE)}
              />
            </Box>
            <Box
              sx={{ display: "flex", alignItems: "center", ml: 2 }}
              data-tut={TourSelector.LOGOUT}
            >
              <Box sx={{ mr: 2 }}>
                <StaffStudentToggle />
              </Box>
              <Box>
                <StaffSchoolSelect />
              </Box>
              <IconButton onClick={logout} aria-label="Logout">
                <FeatherIcon icon="exit" width="24" />
              </IconButton>
            </Box>
          </Toolbar>
        </TopBar>
      )}
    </>
  );
};

export default StaffTopDesktopNavbar;
