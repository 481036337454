export enum LogSeverity {
  INFO = "INFO",
  WARN = "WARNING",
  ERROR = "ERROR",
  NOTICE = "NOTICE",
}

export enum LogEventType {
  NEW_USER_CREATED = "new-user-created",
  USER_SIGNUP_ERROR = "user-signup-error",
  USER_LOGGED_IN = "user-logged-in",
  USER_LOGIN_ERROR = "user-login-error",
  RESET_PASSWORD_ERROR = "reset-password-error",
  ERROR_GETTING_UID_BY_EMAIL = "error-getting-uid-by-email",
  GOOGLE_MAPS_LOADING_ERROR = "google-maps-loading-error",
  GOOGLE_MAPS_INSUFFICIENT_COMPONENTS_FOR_ADDRESS = "google-maps-insufficient-components-for-address",
  USER_CREATED_PROFILE = "user-created-profile",
  USER_CREATE_PROFILE_ERROR = "user-create-profile-error",
  ERROR_CREATING_EMAIL_USER = "error creating email user",
  EMAIL_USER_CREATED = "email user created",
  ERROR_RESETTING_PASSWORD = "error-resetting-password",
  PASSWORD_SUCCSESSFULLY_RESET = "password-successfully-reset",
  USER_EMAIL_SIGN_IN_ERROR = "user-email-sign-in-error",
  USER_EMAIL_SIGNED_IN = "user-email-signed-in",
  USER_GOOGLE_SIGN_IN_ERROR = "user-google-sign-in-error",
  USER_GOOGLE_SIGNED_IN = "user-google-signed-in",
  ERROR_CLOSING_TOUR = "error-closing-tour",
  CAREER_UNLOCK_SURVEY_SUBMITTED = "career-unlock-survey-submitted",
  CAREER_UNLOCK_SURVEY_ERROR = "career-unlock-survey-error",
  SUBMITTED_STUDENT_ALMA_CHAT = "submitted-student-alma-chat",
  SUBMITTED_STUDENT_ALMA_CHAT_ERROR = "submitted-student-alma-chat-error",
  STUDENT_ALMA_CHAT_RESET = "student-alma-chat-reset",
  STUDENT_ALMA_CHAT_RESET_ERROR = "student-alma-chat-reset-error",
  USER_UPDATED_PROFILE = "user-updated-profile",
  USER_UPDATE_PROFILE_ERROR = "user-update-profile-error",
  PROFILE_MY_WHY_SUBMITTED = "profile-my-why-submitted",
  PROFILE_MY_WHY_ERROR = "profile-my-why-error",
  PROFILE_ACADEMICS_SUBMITTED = "profile-academics-submitted",
  PROFILE_ACADEMICS_ERROR = "profile-academics-error",
  PROFILE_EFC_SUBMITTED = "profile-efc-submitted",
  PROFILE_EFC_ERROR = "profile-efc-error",
  AVATAR_UPLOADED = "avatar-uploaded",
  AVATAR_UPLOAD_ERROR = "avatar-upload-error",
  AVATAR_DOWNLOAD_ERROR = "avatar-download-error",
  USER_MY_NEW_MENTOR_ADDED = "user-my-new-mentor-added",
  USER_MY_NEW_MENTOR_ADD_ERROR = "user-my-new-mentor-add-error",
  USER_MY_EXISTING_MENTOR_ADDED = "user-my-existing-mentor-added",
  USER_MY_EXISTING_MENTOR_ADD_ERROR = "user-my-existing-mentor-add-error",
  USER_MY_MENTOR_DELETED = "user-my-mentor-deleted",
  USER_MY_MENTOR_DELETE_ERROR = "user-my-mentor-delete-error",
  USER_ADDED_VIDEO_INTERACTION = "user-added-video-interaction",
  USER_ADD_VIDEO_INTERACTION_ERROR = "user-add-video-interaction-error",
  USER_UPDATED_VIDEO_INTERACTION = "user-updated-video-interaction",
  USER_UPDATE_VIDEO_INTERACTION_ERROR = "user-update-video-interaction-error",
  SUBMIT_MY_WHY_ANSWER_IN_QUIZ = "submit-my-why-answer-in-quiz",
  SUBMIT_MY_WHY_ANSWER_IN_QUIZ_ERROR = "submit-my-why-answer-in-quiz-error",
  SUBMIT_BEST_DESCRIBES_ME_ANSWER_IN_QUIZ = "submit-best-describes-me-answer-in-quiz",
  SUBMIT_BEST_DESCRIBES_ME_ANSWER_IN_QUIZ_ERROR = "submit-best-describes-me-answer-in-quiz-error",
  SUBMIT_QUIZ = "submit-quiz",
  SUBMIT_QUIZ_ERROR = "submit-quiz-error",
  SUBMIT_QUIZ_ANSWER = "submit-quiz-answer",
  SUBMIT_QUIZ_ANSWER_ERROR = "submit-quiz-answer-error",
  USER_PERSONALITY_TYPE_VIEWED = "user-personality-type-viewed",
  USER_PERSONALITY_TYPE_VIEW_ERROR = "user-personality-type-view-error",
  GOT_VIDEO_FILES = "got-video-files",
  GOT_VIDEO_FILES_ERROR = "got-video-files-error",
  RESET_QUIZ_RESULTS = "reset-quiz-results",
  RESET_QUIZ_RESULTS_ERROR = "reset-quiz-results-error",
  STAFF_UPDATED_USER_PROFILE = "staff-updated-user-profile",
  STAFF_UPDATE_USER_PROFILE_ERROR = "staff-update-user-profile-error",
  STAFF_UPDATED_USER_PROFILE_MY_WHY = "staff-updated-user-profile-my-why",
  STAFF_UPDATE_USER_PROFILE_MY_WHY_ERROR = "staff-update-user-profile-my-why-error",
  STAFF_RESET_QUIZ_RESULTS = "staff-reset-quiz-results",
  STAFF_RESET_QUIZ_RESULTS_ERROR = "staff-reset-quiz-results-error",
  STAFF_UPDATED_USER_ACADEMICS_AND_FINANCE = "staff-updated-user-academics-and-finance",
  STAFF_UPDATE_USER_ACADEMICS_AND_FINANCE_ERROR = "staff-update-user-academics-and-finance-error",
  STAFF_RECOMMENDED_PROGRAM = "staff-recommended-program",
  STAFF_RECOMMEND_PROGRAM_ERROR = "staff-recommend-program-error",
  STAFF_RECOMMENDED_CAREER = "staff-recommended-career",
  STAFF_RECOMMEND_CAREER_ERROR = "staff-recommend-career-error",
  CREATE_STUDENT_FROM_USER_PROVIDER = "create-student-from-user-provider",
  CREATE_STUDENT_FROM_USER_PROVIDER_ERROR = "create-student-from-user-provider-error",
  MANAGE_PROGRAM_DATA = "manage-program-data",
  MANAGE_PROGRAM_DATA_ERROR = "manage-program-data-error",
  DELETE_PROGRAM_DATA = "delete-program-data",
  DELETE_PROGRAM_DATA_ERROR = "delete-program-data-error",
  GOT_WAGE_DATA = "got-wage-data",
  GOT_WAGE_DATA_ERROR = "got-wage-data-error",
  GET_IMAGE_BY_INDUSTRY_ID_ERROR = "get-image-by-industry-id-error",
  SCHOLARSHIP_SAVE_ERROR = "scholarship-save-error",
  ADMIN_UPLOAD_CSV_DATA = "admin-upload-non-college-csv-data",
  UPLOAD_PROVIDER_IMAGE_ERROR = 'upload-provider-image-error',
}
