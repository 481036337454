import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  ProviderProgramRecord,
  StudentRecord,
  StudentsAllForExport,
} from "../../../shared/types/types";
import { loggedInStaffAtom } from "../../../shared/recoil/userAtoms";
import { allSchoolStudentsAtom } from "../../recoil/studentsAtoms";
import useGetFSDocs from "../../../shared/hooks/db/useGetFSDocs";
import { Collection, FunctionName } from "../../../shared/types/enums";
import { generateStudentExportBlankObj } from "../../utils/dataUtils";
import { convertToCSV } from "../../utils/csvUtils";
import { fetchData } from "../../../shared/utils/fetchUtils";
import { parseStudentResponse } from "../../../shared/utils/parserUtils";
import { formatProgramName } from "../../../shared/utils/formatUtils";

type KeysMatching<T extends object, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

type YesNoOnlyKeys = KeysMatching<StudentsAllForExport, "Yes" | "No">;
type StringOnlyKeys = Exclude<keyof StudentsAllForExport, YesNoOnlyKeys>;

function formatForCSV(text: string) {
  // If the text potentially contains commas, quotes, or needs escaping.
  if (text.includes(",") || text.includes('"') || text.includes("\n")) {
    // Surround the text in double quotes.
    text = `"${text}"`;

    // // Replace existing double quotes within the text with two double quotes for proper CSV escaping.
    // text = text.replace(/"/g, '""');
  }
  return text;
}

const getMatchedPrograms = async (uniqueProgramIds: string[]) => {
  const results = await fetchData({
    functionName: FunctionName.GET_PROGRAMS_AND_PROVIDERS_BY_ID,
    payload: { documentIds: uniqueProgramIds },
  });
  const { programs } = (await results.json()) as { programs: ProviderProgramRecord[] };
  return programs;
};

const useProgramListContainer = () => {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);
  const [loading, setLoading] = useState(false);
  const [favoriteOnlyToggle, setFavoriteOnlyToggle] = useState(false);
  const [availableFromAllSchoolsToggle, setAvailableFromAllSchoolsToggle] = useState(false);
  const [fromAllSchoolsToggle, setFromAllSchoolsToggle] = useState(false);
  const [studentsAllForExport, setStudentsAllForExport] = useState("");
  const [sortBy, setSortBy] = useState("program");
  const allEnrolledGroupStudents = useRecoilValue(allSchoolStudentsAtom);
  const [uniqueProviderIds, setUniqueProviderIds] = useState<string[]>([]);
  const [allMatchedIds, setAllMatchedIds] = useState<string[]>([]);
  const [uniqueMatchedIds, setUniqueMatchedIds] = useState<string[]>([]);
  const [matchedPrograms, setMatchedPrograms] = useState<ProviderProgramRecord[]>([]);
  const { getFSDocs } = useGetFSDocs();
  const [allEnrolledDistrictStudents, setAllEnrolledDistrictStudents] = useState<StudentRecord[]>(
    []
  );

  useEffect(() => {
    if (!loggedInStaff) return;
    const getAllDistrictStudents = async () => {
      const allDistrictStudents = await getFSDocs<StudentRecord>({
        col: Collection.STUDENTS,
        config: { where: ["districtId", "==", loggedInStaff.districtId] },
      });
      setAllEnrolledDistrictStudents(parseStudentResponse(allDistrictStudents));
    };
    getAllDistrictStudents();
  }, [getFSDocs, loggedInStaff]);

  useEffect(() => {
    const buildList = async () => {
      setLoading(true);
      const studentsForListBuilding = fromAllSchoolsToggle
        ? [...allEnrolledDistrictStudents]
        : [...allEnrolledGroupStudents];
      const tempAllMatchedIds = studentsForListBuilding.flatMap(
        ({ willowRecommendedProgramIds }) => willowRecommendedProgramIds
      );
      //remove duplicates from allMatchedIds
      const tempUniqueMatchedIds = Array.from(new Set(tempAllMatchedIds));

      //sort so that the most popular programs are at the top
      tempUniqueMatchedIds.sort((a, b) => {
        if (
          tempAllMatchedIds.filter((matchedId) => matchedId === a).length >
          tempAllMatchedIds.filter((matchedId) => matchedId === b).length
        ) {
          return -1;
        }
        return 1;
      });
      setUniqueMatchedIds(tempUniqueMatchedIds);
      setAllMatchedIds(tempAllMatchedIds);
      const programsForList = await getMatchedPrograms(tempUniqueMatchedIds);
      setMatchedPrograms(programsForList ?? []);
      if (!programsForList || programsForList.length === 0) {
        setLoading(false);
        return;
      }
      const allMatchedProviderIds = programsForList.flatMap(({ providerId }) => providerId);
      const filteredProviderIds = Array.from(new Set(allMatchedProviderIds));
      setUniqueProviderIds(filteredProviderIds);
      setLoading(false);
    };
    buildList();
  }, [allEnrolledDistrictStudents, allEnrolledGroupStudents, fromAllSchoolsToggle]);

  // const allUsedProviders = providers.filter((provider) => uniqueMatchedIds.includes(provider.id));
  // const uniqueProviderIds = Array.from(
  //   new Set(allUsedProviders.map((provider) => provider.Prov_ID))
  // );

  useEffect(() => {
    const tempArrayForExport: StudentsAllForExport[] = [];

    //generates the data needed for the overall CSV export
    (fromAllSchoolsToggle ? allEnrolledDistrictStudents : allEnrolledGroupStudents).forEach(
      (student) => {
        const studentExportData = generateStudentExportBlankObj(student);

        student.willowRecommendedProgramIds.forEach((matchedProgramId, index) => {
          const providerNameKey = `providerName${index + 1}` as StringOnlyKeys;
          const programNameKey = `programName${index + 1}` as StringOnlyKeys;
          const heartedKey = `program${index + 1}hearted` as YesNoOnlyKeys;

          const currentProvider = matchedPrograms.find((p) => p.id === matchedProgramId);

          const programName = currentProvider ? formatProgramName({ name: currentProvider?.programName }) : "";

          studentExportData[providerNameKey] = currentProvider?.providerName ?? "";
          studentExportData[programNameKey] = formatForCSV(programName);
          studentExportData[heartedKey] = student.favoriteProgramIds.includes(matchedProgramId)
            ? "Yes"
            : "No";
        });

        tempArrayForExport.push(studentExportData);
      }
    );

    const arrayForExport = convertToCSV(tempArrayForExport);
    setStudentsAllForExport(arrayForExport);
  }, [
    fromAllSchoolsToggle,
    allEnrolledDistrictStudents,
    allEnrolledGroupStudents,
    setStudentsAllForExport,
    matchedPrograms,
  ]);

  useEffect(() => {
    setAvailableFromAllSchoolsToggle(true);
  }, [loggedInStaff]);

  return {
    loading,
    favoriteOnlyToggle,
    setFavoriteOnlyToggle,
    availableFromAllSchoolsToggle,
    fromAllSchoolsToggle,
    setFromAllSchoolsToggle,
    studentsAllForExport,
    setStudentsAllForExport,
    sortBy,
    setSortBy,
    allEnrolledGroupStudents,
    allMatchedIds,
    uniqueMatchedIds,
    uniqueProviderIds,
    matchedPrograms,
    allEnrolledDistrictStudents,
  };
};

export default useProgramListContainer;
