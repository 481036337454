import React, { Dispatch, SetStateAction } from "react";
import useVideoInteractions from "../../hooks/video/useVideoInteractions";
import { Box, IconButton, Typography } from "@mui/material";
import FeatherIcon from "../../../shared/assets/icons/featherIcons/FeatherIcon";
import { REACTION_COLOR } from "../../../shared/types/enums";
import { CareerVideoRecord, VideoInteractionRecord } from "../../types/types";

type Props = {
  url: string;
  studentId: string;
  video: CareerVideoRecord;
  interaction: VideoInteractionRecord | null;
  setInteraction: Dispatch<SetStateAction<VideoInteractionRecord | null>>;
  videoRef: React.RefObject<HTMLVideoElement>;
  handleLearnMoreClick: (video: CareerVideoRecord) => void;
  mobile: boolean;
};

const VideoInteractions = ({
  url,
  studentId,
  video,
  interaction,
  setInteraction,
  videoRef,
  mobile,
  handleLearnMoreClick,
}: Props) => {
  const { handleLikeClick, handleDislikeClick } = useVideoInteractions({
    url,
    studentId,
    video,
    interaction,
    setInteraction,
    videoRef,
  });
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: mobile ? "row" : "column",
          textAlign: "center",
          alignItems: "center",
          ml: 2,
          justifyContent: mobile ? "start" : "end",
          height: "100%",
          gap: 1,
        }}
      >
        {interaction?.interaction !== "liked" && (
          <>
            <IconButton
              sx={{ p: 1, pt: 2, width: 48, height: 48, backgroundColor: "#e6e6e6" }}
              size="medium"
              onClick={handleDislikeClick}
              aria-label="dislike button"
            >
              <FeatherIcon
                icon="thumb-down"
                width="24px"
                height="24px"
                strokeColor={REACTION_COLOR.STROKE}
                fillColor={
                  interaction?.interaction === "disliked"
                    ? REACTION_COLOR.DISLIKE
                    : "transparent"
                }
              />
            </IconButton>
            <Typography sx={{ fontWeight: "600" }}>Dislike</Typography>
          </>
        )}
        {interaction?.interaction !== "disliked" && (
          <>
            <IconButton
              sx={{ p: 1, width: 48, height: 48, backgroundColor: "#e6e6e6" }}
              size="medium"
              onClick={handleLikeClick}
              aria-label="heart button"
            >
              <FeatherIcon
                icon="bookmark"
                width="24px"
                height="24px"
                strokeColor={REACTION_COLOR.STROKE}
                fillColor={
                  interaction?.interaction === "liked"
                    ? REACTION_COLOR.BOOKMARK
                    : "transparent"
                }
              />
            </IconButton>
            <Typography sx={{ fontWeight: "600" }}>Save</Typography>
          </>
        )}

        <>
          <IconButton
            sx={{ p: 1, pt: 1.5, width: 48, height: 48, backgroundColor: "#e6e6e6" }}
            size="medium"
            onClick={() => handleLearnMoreClick(video)}
            aria-label="heart button"
          >
            <FeatherIcon
              icon="info"
              width="24px"
              height="24px"
              strokeColor={REACTION_COLOR.STROKE}
              fillColor={"transparent"}
            />
          </IconButton>
          <Typography sx={{ fontWeight: "600" }}>Info</Typography>
        </>
      </Box>
    </>
  );
};

export default VideoInteractions;
