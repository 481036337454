import { Box, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { Color } from "../../../shared/types/enums";
import { numberToPercent } from "../../../shared/utils/formatUtils";
import { bookmarkedProgramsAtom } from "../../recoil/programAtoms";

const NATIONAL_AVERAGE_COMPLETION = 0.28;

const CompletionOverview = () => {
  const bookmarkedPrograms = useRecoilValue(bookmarkedProgramsAtom);

  let totalCompletion = 0;
  let count = 0;
  for (const program of bookmarkedPrograms) {
    if (!program.completionRate || isNaN(program.completionRate)) continue;
    totalCompletion += Number(program.completionRate);
    count++;
  }
  const averageCompletion = count > 0 ? totalCompletion / count : 0;
  const percentChange = averageCompletion / NATIONAL_AVERAGE_COMPLETION;

  return (
    <>
      {averageCompletion > 0 && (
        <>
          <Box sx={{ padding: 1, textAlign: "left" }}>
            <Typography variant="h5" sx={{ color: Color.MINT }}>
              {numberToPercent({ number: averageCompletion })}
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              In 2021, only {numberToPercent({ number: NATIONAL_AVERAGE_COMPLETION })} of graduates from
              low-income, high-minority high schools completed college in six years, compared to 52%
              from higher-income, low-minority schools. This means your students on average will
              graduate at a rate of {Math.round(percentChange * 100)}%{" "}
              {percentChange > 0 ? "more " : "less "} more than other students in the country by
              attending the programs selected for them.{" "}
              <a
                className="source"
                target="_blank"
                rel="noopener noreferrer"
                href="https://nscresearchcenter.org/wp-content/uploads/2021_HSBenchmarksReport.pdf"
              >
                Source
              </a>
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default CompletionOverview;
