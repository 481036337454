import industries from "../../assets/data/industries.json";
import { Industry } from "../../types/types";
import IndustryCard from "./IndustryCard";
import { Box, Grid, Typography } from "@mui/material";
import useWindowDimensions from "../../hooks/responsiveLayout/useWindowDimensions";

const Industries = () => {
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  const industriesArray: Industry[] = industries.data;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
      <Grid container alignContent="start" spacing="20px">
        {industriesArray.map((industry: any, index: number) => (
          <Grid key={index} item xs={12} sm={6} md={6} lg={6} xl={4}>
            <IndustryCard industry={industry} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Industries;
